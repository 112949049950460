// =============================================================================
// dateLog: display date and hour to YYMMDD HHmmss.sssZ.
// =============================================================================
export const dateLog = (type: string, message: string) => {
  const now = new Date();
  const dateInt =
    (((((now.getFullYear() * 100 + now.getMonth() + 1) * 100 + now.getDate()) *
      100 +
      now.getHours()) *
      100 +
      now.getMinutes()) *
      100 +
      now.getSeconds()) *
      1000 +
    now.getMilliseconds();
  const datestr = dateInt.toString();
  const dateNow =
    datestr.substring(2, 4) + datestr.substring(4, 6) + datestr.substring(6, 8);
  const timeNow =
    datestr.substring(8, 10) +
    datestr.substring(10, 12) +
    datestr.substring(12, 14) +
    "." +
    datestr.substring(14, 17);
  return "[" + type + "] " + dateNow + " " + timeNow + " " + message;
};
