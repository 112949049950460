import { createTheme } from "@mui/material/styles";
import { theme, colors } from "@docaposte-agility/da-design-system";

export default createTheme(theme, {
  palette: {
    background: {
      default: colors.light,
    },
  },

  components: {
    MuiStepConnector: {
      styleOverrides: {
        lineVertical: {
          minHeight: "4.5rem",
          marginBottom: "-0rem",
          marginTop: "-0.5rem",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          height: "1.25rem",
          lineHeight: "1.25rem",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: colors.bleuDigital100,
            backgroundColor: colors.grey30,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.lynch,
          "&.Mui-checked": {
            color: colors.white,
            backgroundColor: colors.userBlue,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: colors.lynch,
          marginLeft: "8px",
        },
      },
    },
  },
});
