import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { theme } from "@docaposte-agility/da-design-system";

// Components. -----------------------------------------------------------------
import { contextECT } from "app/context/Context";
import ConsentBoxWhite from "app/components/common/box/ConsentBoxWhite";

// Services/Utils. -------------------------------------------------------------
import { parseContent } from "utils/parseContent";
import { dateFormat } from "utils/date/dateFormat";

// Types/Const. ----------------------------------------------------------------
import { setStorageValue } from "utils/memoryStorage";

// =============================================================================
// ConsentValidEndNextConsent...
// =============================================================================
interface ConsentValidEndNextConsentProps {}
const ConsentValidEndNextConsent: React.FC<
  ConsentValidEndNextConsentProps
> = () => {
  // Members. ------------------------------------------------------------------
  const { contextSurvey, contextSurveyList, setContextSurveyList } =
    React.useContext(contextECT);
  const [nextSurvey, setNextSurvey] = React.useState({});
  const [sameDate, setSameDate] = React.useState(false);

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    const curSurveyDate = dateFormat(
      contextSurvey["intervention"].date,
      "ddMMyyyy",
    );

    const curAvailableSurvey = contextSurveyList.filter(
      (item) => item["id"] !== contextSurvey.id,
    );
    const curSameSurveyDate = curAvailableSurvey.filter(
      (item) => dateFormat(item["date"], "ddMMyyyy") === curSurveyDate,
    );

    setContextSurveyList(curAvailableSurvey);
    if (curSameSurveyDate.length > 0) {
      setNextSurvey(curSameSurveyDate[0]);
      setSameDate(true);
    } else {
      setNextSurvey(curAvailableSurvey[0]);
      setSameDate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Handlers. -----------------------------------------------------------------
  const handlerNextDoc = () => {
    setStorageValue("step");
    navigate(location.pathname.replace(contextSurvey.id, nextSurvey["id"]), {
      replace: true,
    });
  };

  // Render. -------------------------------------------------------------------
  return (
    <ConsentBoxWhite title="">
      <Grid
        container
        direction={mobile ? "column" : "row"}
        justifyContent="flex-start"
        alignItems="center"
        columns={12}
        sx={{ m: "0rem", p: "1rem" }}
      >
        <Grid item xs sx={{ width: "100%", m: "0rem", p: "0rem" }}>
          <Typography
            variant="h2"
            sx={{
              m: "0rem 0.5rem",
              p: "0rem",
              color: "cobalt.ultramarine",
            }}
          >
            {parseContent(
              sameDate
                ? nextSurvey["title"]
                : t("user.consentValid.step6.next.anotherTitle"),
            )}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              m: "0rem 0.5rem",
              p: "0rem",
              color: "cobalt.lynch",
            }}
          >
            {parseContent(
              sameDate
                ? t("user.consentValid.step6.next.sameMsg", {
                    postProcess: "interval",
                    count: contextSurveyList.length - 1,
                  })
                : t("user.consentValid.step6.next.anotherMsg"),
            )}
          </Typography>
        </Grid>

        <Grid item xs={4} sx={{ m: "0rem", p: "1rem" }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth={true}
            onClick={handlerNextDoc}
          >
            {parseContent(
              sameDate
                ? t("user.consentValid.step6.next.sameButton")
                : t("user.consentValid.step6.next.anotherButton"),
            )}
          </Button>
        </Grid>
      </Grid>
    </ConsentBoxWhite>
  );
};
export default ConsentValidEndNextConsent;
