// =============================================================================
// stringAvatar...
// =============================================================================
function stringAvatar(name?: string) {
  if (name && name.split(" ").length > 1) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  } else if (name && name.length > 0) {
    return {
      children: `${name[0]}`,
    };
  } else {
    return {
      children: null,
    };
  }
}
export default stringAvatar;
