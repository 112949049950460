import React from "react";
import { Grid } from "@mui/material";

// Components. -----------------------------------------------------------------
import { contextECT } from "app/context/Context";

import ConsentValidSignIntro from "app/components/user/ConsentValid/step5/ConsentValidSign-intro";
import ConsentValidSignDocs from "app/components/user/ConsentValid/step5/ConsentValidSign-docs";

// Types/Const. ----------------------------------------------------------------
import { UserStep } from "types/user-step.enum";

// =============================================================================
// ConsentValidSign...
// =============================================================================
interface ConsentValidSignProps {
  onNext: (action: UserStep) => void;
  ipClient: string;
}
const ConsentValidSign: React.FC<ConsentValidSignProps> = ({
  onNext,
  ipClient,
}) => {
  // Members. ------------------------------------------------------------------
  const { contextSurvey } = React.useContext(contextECT);

  // Render. -------------------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{
        m: "0rem",
        p: "1rem",
      }}
    >
      {/* Intro. ----------------------------------------------------------- */}
      <ConsentValidSignIntro />

      {/* Contralia. ------------------------------------------------------- */}
      <ConsentValidSignDocs
        surveyId={contextSurvey.id}
        onNext={onNext}
        ipClient={ipClient}
      />
    </Grid>
  );
};
export default ConsentValidSign;
