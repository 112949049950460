// =============================================================================
// dateNow: output date format (ISOString): "yyyy-MM-ddThh:MM:ss.sssZ"
// =============================================================================
export const dateNow = (extra?: string) => {
  const date = new Date().toISOString();
  if (extra === "FirstDayOfMonth") {
    return date.slice(0, 8) + "01" + date.slice(10);
  } else {
    return date;
  }
};
