import React from "react";
import {
  Button,
  Grid,
  Link,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "@docaposte-agility/da-design-system";

// Components. -----------------------------------------------------------------
import DecorationsPage from "app/components/common/DecorationsPage";

// Services/Utils. -------------------------------------------------------------
import { setStorageValue } from "utils/memoryStorage";
import { useTranslation } from "react-i18next";
import { parseContent } from "utils/parseContent";

// =============================================================================
// Accueil...
// =============================================================================
interface AccueilProps {
  handleConnect: () => void;
}
const Accueil: React.FC<AccueilProps> = ({ handleConnect }) => {
  // Members. ------------------------------------------------------------------
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { t } = useTranslation();

  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    setStorageValue("breadcrumbs");
  }, []);

  //Handlers. ------------------------------------------------------------------
  const connect = () => {
    setStorageValue("connected", true);
    handleConnect();
  };

  // Render. -------------------------------------------------------------------
  return (
    <Grid container>
      {/* Accueil. ------------------------------------------------------------- */}
      <Grid container flexDirection="column" alignItems="center">
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={mobile ? "center" : "space-between"}
        >
          <Paper
            elevation={3}
            sx={{
              p: "48px",
              borderRadius: "10px",
              ml: mobile ? "0px" : "140px",
              mt: mobile ? "140px" : "0px",
              width: "30%",
              minWidth: "311px",
            }}
          >
            {/* Title. ------------------------------------------------------- */}
            <Grid item sx={{ pb: "30px" }}>
              <Typography variant="h2" sx={{ color: "cobalt.lynch" }}>
                {parseContent(t("login.home.title"))}
              </Typography>
              <Typography variant="h1" sx={{ color: "cobalt.bleuDigital100" }}>
                {parseContent(t("common.produit"))}
              </Typography>
            </Grid>

            {/* Login button. ------------------------------------------------ */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button data-testid="test-connect" onClick={connect} fullWidth>
                {parseContent(t("login.home.connect"))}
              </Button>
            </Grid>
          </Paper>
          {/* Right section with shapes. --------------------------------------- */}
          {!mobile && <DecorationsPage logo="/images/chu.png" />}
        </Grid>
        {/* Récupérer le logo depuis une requête back à l'aide de la clinique */}
        {mobile && (
          <Link
            href="https://accessibilite.numerique.gouv.fr/obligations/mentions-et-pages-obligatoires/"
            target="_blank"
            color="cobalt.ultramarine"
            sx={{ mt: "16px" }}
          >
            {parseContent(t("footer.Accessibility"))}
          </Link>
        )}
      </Grid>
      {!mobile && (
        <Link
          href="https://accessibilite.numerique.gouv.fr/obligations/mentions-et-pages-obligatoires/"
          target="_blank"
          color="cobalt.ultramarine"
          ml="32px"
          mt="-32px"
        >
          {parseContent(t("footer.Accessibility"))}
        </Link>
      )}
    </Grid>
  );
};
export default Accueil;
