import React from "react";
import {
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Dialog, theme } from "@docaposte-agility/da-design-system";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Components. -----------------------------------------------------------------
import Field from "app/formField/Field";
import RadioBoxField from "app/formField/RadioBoxField";

// Services/Utils. -------------------------------------------------------------
import { parseContent } from "utils/parseContent";

// =============================================================================
// ChangeTeam...
// =============================================================================
interface ChangeTeamProps {
  open: boolean;
  title: string;
  teams: { label: string; value: number; isClinicalStudy: boolean }[];
  activeTeam: number;
  onResponse: (index: number) => void;
}
const ChangeTeam: React.FC<ChangeTeamProps> = ({
  open,
  title,
  teams,
  activeTeam,
  onResponse,
}) => {
  // Members. ------------------------------------------------------------------
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { t } = useTranslation();

  // Form control. -------------------------------------------------------------
  const { control, watch } = useForm<any>({
    defaultValues: {
      label: teams[activeTeam].label,
      value: teams[activeTeam].value,
    },
  });

  function hasInvestigationCenters() {
    return teams.some((team) => team.isClinicalStudy);
  }

  function hasMedicalTeams() {
    return teams.some((team) => !team.isClinicalStudy);
  }

  // Render. -------------------------------------------------------------------
  return (
    <Dialog
      data-testid="ChangeTeam-modal"
      open={open}
      fullWidth={mobile}
      sx={{ p: 0, m: 0 }}
      PaperProps={{
        sx: {
          position: mobile ? "fixed" : "",
          bottom: 0,
          left: 0,
          m: 0,
          p: 0,
          width: "100%",
        },
      }}
      onClose={() => {
        onResponse(-1);
      }}
    >
      {/* Title. ----------------------------------------------------------- */}
      <DialogTitle>
        {/* Use div to remove warning "<h4> cannot appear as a child of <h2>"" */}
        <div>
          <Typography
            variant="h4"
            sx={{ textAlign: "start", color: "cobalt.ultramarine" }}
          >
            {parseContent(title)}
          </Typography>
        </div>
      </DialogTitle>
      <Divider sx={{ backgroundColor: "cobalt.grey10" }} />
      {/* Message. --------------------------------------------------------- */}
      <DialogContent
        sx={{ textAlign: "start", p: mobile ? "1rem" : "0rem", m: 0 }}
      >
        <Grid sx={{ mt: "1.5rem" }}>
          {hasMedicalTeams() && (
            <>
              <Typography variant="subtitle2" color="cobalt.lynch">
                {parseContent(
                  t("pro.nav.menu.message.changeTeam.medicalTeams"),
                )}
              </Typography>
              <Grid item xs={12} sm={12} sx={{ m: 0, p: 0 }}>
                <Field
                  as={RadioBoxField}
                  id="team"
                  control={control}
                  direction="column"
                  radioItems={teams.filter(
                    (team) => team.isClinicalStudy === false,
                  )}
                  value={watch("value")}
                  onChange={(e, index) => {
                    onResponse(index);
                  }}
                  sx={{
                    ".MuiFormControlLabel-root": { m: "8px 8px 8px 0px" },
                  }}
                />
              </Grid>
            </>
          )}
          {hasInvestigationCenters() && (
            <>
              <Typography
                variant="subtitle2"
                color="cobalt.lynch"
                sx={{ mt: hasMedicalTeams() ? "0.5rem" : "0rem" }}
              >
                {parseContent(
                  t("pro.nav.menu.message.changeTeam.investigationCenters"),
                )}
              </Typography>
              <Grid item xs={12} sm={12} sx={{ m: 0, p: 0 }}>
                <Field
                  as={RadioBoxField}
                  id="team"
                  control={control}
                  direction="column"
                  radioItems={teams.filter(
                    (team) => team.isClinicalStudy === true,
                  )}
                  value={watch("value")}
                  onChange={(e, index) => {
                    onResponse(index);
                  }}
                  sx={{
                    ".MuiFormControlLabel-root": { m: "8px 8px 8px 0px" },
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <Divider sx={{ backgroundColor: "cobalt.grey10" }} />
    </Dialog>
  );
};
export default ChangeTeam;
