import parse from "html-react-parser";
import DOMPurify from "isomorphic-dompurify";

// =============================================================================
// parseContent...
// =============================================================================
export const parseContent = (content: string) => {
  return parse(
    DOMPurify.sanitize(content, {
      FORCE_BODY: true,
      SANITIZE_NAMED_PROPS: true,
    }),
  );
};
