import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// Services/Utils. -------------------------------------------------------------
import keycloak from "config/keycloak";

// =============================================================================
// useGetIP...
// =============================================================================
export const useGetUserIP = (
  config: UseMutationOptions<{ id_token: string }, AxiosError, {}> = {},
) => {
  return useMutation(
    // eslint-disable-next-line no-empty-pattern
    ({}) =>
      Axios.get("/V2/users/current_ip", {
        baseURL: window.__ENV__.REACT_APP_BO_AUTH_URL,
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      }),
    {
      ...config,
    },
  );
};
