export interface ToasterType {
  type: TypeToaster;
  title: string;
  message: string;
  autoClose?: number;
  toastId?: string;
}

export const emptyToaster = {
  type: "default" as TypeToaster,
  title: "",
  message: "",
  autoClose: undefined,
};

export type TypeToaster = "info" | "success" | "warning" | "error" | "default"
