import React from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
} from "@mui/material";

import { parseContent } from "utils/parseContent";

// =============================================================================
// RadioField...
// =============================================================================
interface RadiofieldProps {
  label: string;
  value?: string;
  direction: string;
  error?: boolean;
  radioItems?: Array<{
    label: React.ReactNode;
    value: string;
  }>;
  [x: string]: any;
}
const RadioField = (props: RadiofieldProps) => {
  const {
    label = "",
    value = "",
    helperText = "",
    direction = "",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error = false,
    radioItems = [],
    ...other
  } = props;

  return (
    <>
      <Typography variant="subtitle2" sx={{ mb: "8px" }}>
        {parseContent(label)}
      </Typography>
      <MuiRadioGroup
        row={direction === "row" ? true : false}
        value={value}
        sx={{ pl: "10px", color: "cobalt.ultramarine" }}
        {...other}
      >
        {radioItems &&
          radioItems.map((radioItem) => (
            <FormControlLabel
              data-testid={"radioGroup-item-" + radioItem.value}
              key={radioItem.value}
              value={radioItem.value}
              control={<Radio />}
              label={radioItem.label}
              sx={{
                m: "12px 8px 12px 0px",
                p: "16px",
                border: "1px solid",
                borderColor: "cobalt.selected",
                borderRadius: "8px",
                backgroundColor:
                  value === radioItem.value
                    ? "cobalt.bleuDigital05"
                    : "cobalt.white",
              }}
            />
          ))}
      </MuiRadioGroup>
      <Typography
        variant="body3"
        sx={{
          fontStyle: "italic",
          color: "cobalt.redText",
        }}
      >
        {helperText}
      </Typography>
    </>
  );
};
export default RadioField;
