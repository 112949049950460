// =============================================================================
// PatientType...
// =============================================================================
export interface PatientType {
  id: string;
  language_id: number;
  username: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  birthplace: string;
  birthplace_id: string;
  birthplace_postcode: string;
  city: string;
  city_id: string;
  postcode: string;
  gender: string;
  security_number: string;
  phone_number: string;
  address: string;
  email: string;
  tutor: TutorPatientType[];
}
export const emptyPatient = {
  id: "",
  language_id: 1,
  username: "",
  firstname: "",
  lastname: "",
  birthdate: "",
  birthplace: "",
  birthplace_id: "",
  birthplace_postcode: "",
  city: "",
  city_id: "",
  postcode: "",
  gender: "",
  security_number: "",
  phone_number: "",
  address: "",
  email: "",
  tutor: [],
};

// =============================================================================
// TutorPatientType...
// =============================================================================
export interface TutorPatientType {
  id: string;
  language_id: number;
  username: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  birthplace: string;
  birthplace_id: string;
  birthplace_postcode: string;
  city: string;
  city_id: string;
  gender: string;
  security_number: string;
  phone_number: string;
  address: string;
  email: string;
}
export const emptyTutorPatient = {
  id: "",
  language_id: 1,
  username: "",
  firstname: "",
  lastname: "",
  birthdate: "",
  birthplace: "",
  birthplace_id: "",
  birthplace_postcode: "",
  city: "",
  city_id: "",
  gender: "",
  security_number: "",
  phone_number: "",
  address: "",
  email: "",
};
