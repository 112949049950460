import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { CobaltIcon, theme } from "@docaposte-agility/da-design-system";

// Components. -----------------------------------------------------------------
import { contextECT } from "app/context/Context";
import ConsentBoxWhite from "app/components/common/box/ConsentBoxWhite";

// Services/Utils. -------------------------------------------------------------
import { dateLog } from "utils/date/dateLog";
import { parseContent } from "utils/parseContent";
import { setStorageValue } from "utils/memoryStorage";
import { usePostRejectSurvey } from "services/survey/usePostRejectSurvey";
import Toaster from "app/components/common/Toaster";

// Types/Const. ----------------------------------------------------------------
import { ToasterType, emptyToaster } from "types/toaster.type";

// Types/Const. ----------------------------------------------------------------

// =============================================================================
// ConsentValidEndReject...
// =============================================================================
interface ConsentValidEndRejectProps {}
const ConsentValidEndReject: React.FC<ConsentValidEndRejectProps> = () => {
  // Members. ------------------------------------------------------------------
  const { contextSurvey } = React.useContext(contextECT);
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const [toasterMessage, setToasterMessage] =
    React.useState<ToasterType>(emptyToaster);

  // Backend. ------------------------------------------------------------------
  const { mutate: postRejectSurvey } = usePostRejectSurvey({
    onSuccess: (data) => {
      console.debug(dateLog("BKO", "usePostRejectSurvey.onSuccess"), data);
    },
    onError: (error) => {
      console.debug(dateLog("BKO", "usePostRejectSurvey.onError"), error);
      setToasterMessage({
        type: "error",
        title: t("message.backendErrorTitle"),
        message: t("message.backendErrorMsg"),
        autoClose: 4000,
      });
    },
  });

  //Hooks. ---------------------------------------------------------------------
  React.useEffect(() => {
    postRejectSurvey({
      surveyId: contextSurvey.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers. -----------------------------------------------------------------
  const handleExit = () => {
    setStorageValue("step");
    const splitPath = location.pathname.split("/");
    splitPath.pop();
    navigate(splitPath.join("/"), { replace: true });
  };
  // Render. -------------------------------------------------------------------
  return (
    <>
      {/* Toaster. --------------------------------------------------------- */}
      {toasterMessage && <Toaster message={toasterMessage} />}
      <ConsentBoxWhite title="">
        <Grid
          container
          direction={mobile ? "column" : "row"}
          justifyContent="flex-start"
          alignItems="center"
          columns={12}
          sx={{ m: "0rem", p: "1rem" }}
        >
          {/* Icon. ---------------------------------------------------------- */}
          <Grid
            item
            alignSelf={mobile ? "center" : "flex-start"}
            xs="auto"
            sx={{ m: "0rem 0.25rem", p: "0rem" }}
          >
            <CobaltIcon
              name="x"
              fontSize={24}
              sx={{
                color: "cobalt.redIcon",
              }}
            />
          </Grid>

          {/* Texte. ------------------------------------------------------- */}
          <Grid
            item
            xs
            alignSelf={mobile ? "center" : "flex-start"}
            sx={{ m: "0rem 0.25rem", p: "0rem" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                p: "0rem",
                m: "0rem",
                textAlign: mobile ? "center" : "start",
                color: "cobalt.ultramarine",
              }}
            >
              {parseContent(
                t("user.consentValid.step6.reject.title", {
                  title: contextSurvey["title"],
                }),
              )}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                p: "0.5rem 0rem 0rem",
                m: "0rem",
                textAlign: mobile ? "center" : "start",
                color: "cobalt.ultramarine",
              }}
            >
              {parseContent(t("user.consentValid.step6.reject.message"))}
            </Typography>
          </Grid>

          {/* Action. --------------------------------------------------------- */}
          <Grid item xs={6} sx={{ m: "0rem", p: "0rem 0.5rem" }}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth={true}
              onClick={handleExit}
            >
              {t("user.consentValid.step6.reject.button")}
            </Button>
          </Grid>
        </Grid>
      </ConsentBoxWhite>
    </>
  );
};
export default ConsentValidEndReject;
