import React from "react";
import {
  Grid,
  Step,
  Stepper,
  StepLabel,
  Typography,
  StepContent,
} from "@mui/material";

// Components. -----------------------------------------------------------------
import { contextECT } from "app/context/Context";
import NavDrawerHeader from "app/components/common/drawer/NavDrawerHeader";
import NavDrawerFooter from "app/components/common/drawer/NavDrawerFooter";

// Services/Utils. -------------------------------------------------------------
import { parseContent } from "utils/parseContent";

// Types/Const. ----------------------------------------------------------------
import { ConsentValidStepperType } from "types/consent-valid-stepper.type";

// =============================================================================
// StepVerticalDrawerTrial...
// =============================================================================
interface StepVerticalDrawerProps {
  steps: ConsentValidStepperType[];
  activeStep: number;
  userName: string;
}
const StepVerticalDrawerTrial: React.FC<StepVerticalDrawerProps> = ({
  steps,
  activeStep,
  userName,
}) => {
  // Members. ------------------------------------------------------------------
  const { contextIntroQuestion } = React.useContext(contextECT);

  const displayStep = (labelId: string) => {
    // Skip intro when no question available. ----------------------------------
    if (labelId.includes("step2") && contextIntroQuestion.intro.length === 0) {
      return "none";
    }

    // Skip conclusion when no question available. -----------------------------
    if (
      labelId.includes("step4") &&
      contextIntroQuestion.conclusion.length === 0
    ) {
      return "none";
    }

    // Display step. -----------------------------------------------------------
    return "";
  };
  // Render. -------------------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{
        height: "100%",
        width: "100%",
        m: "0rem",
        p: "0rem",
        backgroundColor: "cobalt.white",
        position: "fixed",
        top: "0rem",
      }}
    >
      {/* Header. -------------------------------------------------------- */}
      <NavDrawerHeader mode="user" />

      {/* Steps. --------------------------------------------------------- */}
      <Grid item sx={{ width: "240px", backgroundColor: "cobalt.white" }}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{
            pb: "0rem",
            mb: "0rem",
            borderTop: "1px solid",
            borderColor: "cobalt.grey10",
          }}
        >
          {steps &&
            steps.map((label, index) => (
              <Step key={label.id} sx={{ display: displayStep(label.id) }}>
                <StepLabel>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      m: "0rem",
                      p: "0rem",
                      whiteSpace: "pre-line",
                      cursor: "default",
                    }}
                  >
                    {parseContent(label.title)}
                  </Typography>
                </StepLabel>
                <StepContent sx={{ height: "1.5rem" }} />
              </Step>
            ))}
        </Stepper>
      </Grid>
      <Grid
        item
        xs
        sx={{
          width: "240px",
          borderBottom: "1px solid",
          borderColor: "cobalt.grey10",
        }}
      ></Grid>

      {/* Footer. -------------------------------------------------------- */}
      <NavDrawerFooter userName={userName} mode="user" selected="" />
    </Grid>
  );
};
export default StepVerticalDrawerTrial;
