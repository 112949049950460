import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

// Types/Const. ----------------------------------------------------------------
import { HeaderActions } from "types/header.enum";
import { parseContent } from "utils/parseContent";

// =============================================================================
// Header...
// =============================================================================
interface HeaderProps {
  goBackButton?: boolean;
  exitButton?: boolean;
  addPatientButton?: boolean;
  assignConsentButton?: boolean;
  addModelButton?: boolean;
  addArchiveButton?: boolean;
  seePreview?: boolean;
  validateButton?: boolean;
  cancelButton?: boolean;
  changeModelButton?: boolean;
  title: string;
  subTitle: string;
  onHeader: (action: HeaderActions) => void;
}
const Header: React.FC<HeaderProps> = ({
  goBackButton = false,
  exitButton = false,
  addPatientButton = false,
  assignConsentButton = false,
  addModelButton = false,
  addArchiveButton = false,
  seePreview = false,
  validateButton = false,
  cancelButton = false,
  changeModelButton = false,
  title,
  subTitle,
  onHeader,
}) => {
  // Members. ------------------------------------------------------------------
  const { t } = useTranslation();

  // Render. -------------------------------------------------------------------
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        height: "108px",
        backgroundColor: "cobalt.light",
        p: "0rem",
        m: "0rem",
        width: "100%",
      }}
    >
      {/* Body. ------------------------------------------------------------ */}
      <Grid item xs sx={{ p: "0rem", m: "0rem" }}>
        {/* GoBack. -------------------------------------------------------- */}
        {goBackButton ? (
          <Button
            data-testid="Header-button-goBack"
            variant="text"
            sx={{ m: "0", p: "0" }}
            startIcon={
              <CobaltIcon name="arrow-left" color="cobalt.bleuDigital100" />
            }
            onClick={() => {
              onHeader(HeaderActions.GOBACK);
            }}
          >
            <Typography variant="h5" sx={{ color: "cobalt.bleuDigital100" }}>
              {parseContent(t("header.goBack"))}
            </Typography>
          </Button>
        ) : (
          <Typography variant="h5" sx={{ height: "40px" }} />
        )}

        {/* Title. --------------------------------------------------------- */}
        <Typography
          variant="h1"
          sx={{ m: "0px 0px 5px 0px", p: "0", color: "cobalt.ultramarine" }}
        >
          {parseContent(title)}
        </Typography>

        {/* Subtitle. ------------------------------------------------------ */}
        <Typography variant="h5" sx={{ color: "cobalt.lynch" }}>
          {parseContent(subTitle)}
        </Typography>
      </Grid>

      {/* Add patient. ----------------------------------------------------- */}
      {addPatientButton && (
        <Grid
          item
          sx={{
            p: "0rem",
            m: "0rem",
          }}
        >
          <Button
            variant="text"
            sx={{ m: "0rem 0.5rem", p: "0rem 1rem" }}
            onClick={() => {
              onHeader(HeaderActions.ADDPATIENT);
            }}
          >
            <Typography
              variant="h5"
              color="cobalt.bleuDigital100"
              sx={{ m: "0rem", p: "0rem" }}
            >
              {parseContent(t("header.addPatient"))}
            </Typography>
          </Button>
        </Grid>
      )}

      {/* Assign concent. -------------------------------------------------- */}
      {assignConsentButton && (
        <Grid
          item
          sx={{
            p: "0rem",
            m: "0rem",
          }}
        >
          <Button
            variant="contained"
            sx={{
              m: "0rem 0.5rem",
              p: "0rem 1rem",
              backgroundColor: "cobalt.bleuDigital100",
            }}
            onClick={() => {
              onHeader(HeaderActions.ASSIGNCONSENT);
            }}
          >
            <Typography
              variant="h5"
              sx={{
                m: "0rem",
                p: "0rem",
                color: "cobalt.white",
              }}
            >
              {parseContent(t("header.assignConsent"))}
            </Typography>
          </Button>
        </Grid>
      )}

      {/* Add model. ------------------------------------------------------- */}
      {addModelButton && (
        <Grid
          item
          sx={{
            p: "0rem",
            m: "0rem",
          }}
        >
          <Button
            variant="contained"
            sx={{ m: "0rem 0.5rem", p: "0rem 1rem" }}
            onClick={() => {
              onHeader(HeaderActions.ADDMODEL);
            }}
          >
            <Typography
              variant="h5"
              sx={{ m: "0rem", p: "0rem", color: "cobalt.white" }}
            >
              {parseContent(t("header.addModel"))}
            </Typography>
          </Button>
        </Grid>
      )}

      {/* Archive. --------------------------------------------------------- */}
      {addArchiveButton && (
        <Grid
          item
          sx={{
            p: "0rem",
            m: "0rem",
          }}
        >
          <Button
            variant="text"
            sx={{ m: "0rem 0.5rem", p: "0rem 1rem" }}
            onClick={() => {
              onHeader(HeaderActions.ARCHIVE);
            }}
          >
            <Typography
              variant="h5"
              sx={{ m: "0rem", p: "0rem", color: "cobalt.bleuDigital100" }}
            >
              {parseContent(t("header.archive"))}
            </Typography>
          </Button>
        </Grid>
      )}

      {/* exit. ---------------------------------------------------------- */}
      {exitButton && (
        <Grid
          item
          sx={{
            p: "0rem",
            m: "0rem",
          }}
        >
          <Button
            data-testid="Header-button-exit"
            variant="text"
            sx={{ m: "0", p: "0" }}
            onClick={() => {
              onHeader(HeaderActions.EXIT);
            }}
          >
            <Typography
              variant="h5"
              sx={{ m: "0", p: "0", color: "cobalt.bleuDigital100" }}
            >
              {parseContent(t("header.exit"))}
            </Typography>
          </Button>
        </Grid>
      )}

      {/* changeModel. ---------------------------------------------------------- */}
      {changeModelButton && (
        <Grid
          item
          sx={{
            p: "0rem",
            m: "0rem",
          }}
        >
          <Button
            variant="text"
            sx={{ m: "0rem 0.5rem", p: "0rem 1rem" }}
            onClick={() => {
              onHeader(HeaderActions.CHANGEMODEL);
            }}
          >
            <Typography
              variant="h5"
              sx={{ m: "0rem", p: "0rem", color: "cobalt.bleuDigital100" }}
            >
              {parseContent(t("header.changeModel"))}
            </Typography>
          </Button>
        </Grid>
      )}

      {/* cancel. ---------------------------------------------------------- */}
      {cancelButton && (
        <Grid
          item
          sx={{
            p: "0rem",
            m: "0rem",
          }}
        >
          <Button
            variant="text"
            sx={{ m: "0rem 0.5rem", p: "0rem 1rem" }}
            onClick={() => {
              onHeader(HeaderActions.CANCEL);
            }}
          >
            <Typography
              variant="h5"
              sx={{ m: "0rem", p: "0rem", color: "cobalt.bleuDigital100" }}
            >
              {parseContent(t("header.cancel"))}
            </Typography>
          </Button>
        </Grid>
      )}

      {/* validate. ---------------------------------------------------------- */}
      {validateButton && (
        <Grid
          item
          sx={{
            p: "0rem",
            m: "0rem",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              m: "0rem 0.5rem",
              p: "0rem 1rem",
              borderColor: "cobalt.bleuDigital100",
            }}
            onClick={() => {
              onHeader(HeaderActions.VALIDATE);
            }}
          >
            <Typography
              variant="h5"
              sx={{
                m: "0rem",
                p: "0rem",
                color: "cobalt.bleuDigital100",
              }}
            >
              {parseContent(t("header.validate"))}
            </Typography>
          </Button>
        </Grid>
      )}

      {/* Add model. ------------------------------------------------------- */}
      {seePreview && (
        <Grid
          item
          sx={{
            p: "0rem",
            m: "0rem",
          }}
        >
          <Button
            variant="outlined"
            sx={{ m: "0rem 0.5rem", p: "0rem 1rem" }}
            onClick={() => {
              onHeader(HeaderActions.PREVIEW);
            }}
          >
            <Typography
              variant="h5"
              sx={{ m: "0rem", p: "0rem", color: "cobalt.bleuDigital100" }}
            >
              {parseContent(t("header.seePreview"))}
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default Header;
