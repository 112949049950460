// =============================================================================
// getAllObjectKeys...
// =============================================================================
function getAllObjectKeys(obj, pkey = "") {
  let keys: string[] = [];
  for (let key in obj) {
    keys.push(pkey + key);
    if (typeof obj[key] === "object") {
      keys = keys.concat(getAllObjectKeys(obj[key], pkey + key + "."));
    }
  }
  return keys;
}

export default getAllObjectKeys;
