import * as React from "react";

// Services/Utils. -------------------------------------------------------------
import { dateLog } from "utils/date/dateLog";

// Types/Const. ----------------------------------------------------------------
import { ProMedicalTeamsType } from "types/pro-medical-teams.type";
import { MedicType, emptyMedic } from "types/pro-medic.type";
import { LanguageType, emptyLanguages } from "types/pro-language.type";
import {
  SurveyType,
  emptySurvey,
  SurveyTypeGraphqlList,
} from "types/survey.type";
import { ProPatientListType } from "types/pro-patient-list.type";

// Utils. ----------------------------------------------------------------------
import { setStorageValue } from "utils/memoryStorage";

// exported ECT context Pro. ---------------------------------------------------
export const contextProECT = React.createContext({
  contextActiveMedicalTeam: -1,
  contextActiveClinic: -1,
  contextMedicalTeams: [] as ProMedicalTeamsType[],
  contextMedic: {} as MedicType,
  contextLanguages: {} as LanguageType[],
  contextTypeSurvey: [],
  contextSurveyDraft: {} as SurveyType,
  contextProGraphql: {} as SurveyTypeGraphqlList,
  setContextProGraphql: (data: SurveyTypeGraphqlList) => null,
  setContextSurveyDraft: (data: SurveyType) => null,
  setContextTypeSurvey: (list: []) => null,
  contextPatientList: [] as ProPatientListType[],
  setContextActiveMedicalTeam: (nu: number) => null,
  setContextActiveClinic: (nu: number) => null,
  setContextMedicalTeams: (data: ProMedicalTeamsType[]) => null,
  setContextMedic: (data: MedicType) => null,
  setContextLanguages: (data: LanguageType[]) => null,
  setContextPatientList: (data: ProPatientListType[]) => null,
});

// =============================================================================
// ContextPro...
// =============================================================================
interface ContextProProviderProps {
  children: React.ReactNode;
}
const ContextProProvider: React.FC<ContextProProviderProps> = ({
  children,
}) => {
  // ActiveMedicalTeam. --------------------------------------------------------
  const [contextActiveMedicalTeam, setCtxtActiveMedicalTeam] =
    React.useState(-1);
  const setContextActiveMedicalTeam = (index: number) => {
    console.debug(dateLog("CTX", "setContextActiveMedicalTeam(" + index + ")"));
    setStorageValue("medicTeam", index);
    setCtxtActiveMedicalTeam(index);
    return null;
  };

  // ActiveClinic. -------------------------------------------------------------
  const [contextActiveClinic, setCtxtActiveClinic] = React.useState(-1);
  const setContextActiveClinic = (index: number) => {
    console.debug(dateLog("CTX", "setContextActiveClinic(" + index + ")"));
    setStorageValue("clinic", index);
    setCtxtActiveClinic(index);
    return null;
  };

  // MedicalTeams. -------------------------------------------------------------
  const [contextMedicalTeams, setCtxtMedicalTeams] = React.useState<
    ProMedicalTeamsType[]
  >([]);
  const setContextMedicalTeams = (data: ProMedicalTeamsType[]) => {
    console.debug(dateLog("CTX", "setContextMedicalTeams()"), data);
    setCtxtMedicalTeams(data);
    return null;
  };

  // Medic. --------------------------------------------------------------------
  const [contextMedic, setCtxtMedic] = React.useState<MedicType>(emptyMedic);
  const setContextMedic = (data: MedicType) => {
    console.debug(dateLog("CTX", "setContextMedic()"), data);
    setCtxtMedic(data);
    return null;
  };

  // Language. -----------------------------------------------------------------
  const [contextLanguages, setCtxtLanguages] =
    React.useState<LanguageType[]>(emptyLanguages);
  const setContextLanguages = (data: LanguageType[]) => {
    console.debug(dateLog("CTX", "setContextLanguages()"), data);
    setCtxtLanguages(data);
    return null;
  };

  // TypeSurvey. -------------------------------------------------------------
  const [contextTypeSurvey, setCtxTypeSurvey] = React.useState<[]>([]);
  const setContextTypeSurvey = (data: []) => {
    setCtxTypeSurvey(data);
    return null;
  };

  // SurveyDraft. -------------------------------------------------------------
  const [contextSurveyDraft, setCtxSurveydraft] = React.useState<SurveyType>({
    ...emptySurvey,
  });
  const setContextSurveyDraft = (data: SurveyType) => {
    setCtxSurveydraft(data);
    return null;
  };

  // ContextProGraphql. -------------------------------------------------------------
  const [contextProGraphql, setCtxProGraphql] =
    React.useState<SurveyTypeGraphqlList>({
      id: -1,
      survey_types: [],
      survey_specialities: [],
    });

  const setContextProGraphql = (data: SurveyTypeGraphqlList) => {
    setCtxProGraphql(data);

    return null;
  };

  // PatientList. --------------------------------------------------------------
  const [contextPatientList, setCtxtPatientList] = React.useState<
    ProPatientListType[]
  >([]);
  const setContextPatientList = (data: ProPatientListType[]) => {
    console.debug(dateLog("CTX", "setContextPatientList()"), data);
    setCtxtPatientList(data);
    return null;
  };

  // Provider. -----------------------------------------------------------------
  const value = React.useMemo(
    () => ({
      contextActiveMedicalTeam,
      contextActiveClinic,
      contextMedicalTeams,
      contextMedic,
      contextLanguages,
      contextPatientList,
      setContextActiveMedicalTeam,
      setContextActiveClinic,
      setContextMedicalTeams,
      contextTypeSurvey,
      setContextTypeSurvey,
      contextSurveyDraft,
      setContextSurveyDraft,
      setContextMedic,
      setContextLanguages,
      setContextPatientList,
      contextProGraphql,
      setContextProGraphql,
    }),
    [
      contextActiveMedicalTeam,
      contextActiveClinic,
      contextLanguages,
      contextMedic,
      contextMedicalTeams,
      contextPatientList,
      contextTypeSurvey,
      contextSurveyDraft,
      contextProGraphql,
    ],
  );
  return (
    <contextProECT.Provider value={value}>{children}</contextProECT.Provider>
  );
};
export default ContextProProvider;
