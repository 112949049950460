import React from "react";
import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { CobaltIcon, colors } from "@docaposte-agility/da-design-system";

// Components. -----------------------------------------------------------------

// Services/Utils. -------------------------------------------------------------

// Types/Const. ----------------------------------------------------------------
import { ConsentValidStepperType } from "types/consent-valid-stepper.type";
import { HeaderActions } from "types/header.enum";

// =============================================================================
// StepHorizontalDrawer...
// =============================================================================
interface StepHorizontalDrawerProps {
  steps: ConsentValidStepperType[];
  activeStep: number;
  onHeader: (action: HeaderActions) => void;
}
const StepHorizontalDrawer: React.FC<StepHorizontalDrawerProps> = ({
  steps,
  activeStep,
  onHeader,
}) => {
  // Render. -------------------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{
        m: "0rem",
        p: "0rem",
        width: "100%",
      }}
    >
      {/* Drawer. ---------------------------------------------------------- */}
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        sx={{
          m: "0rem",
          p: ".8rem .5rem",
          width: "100%",
          backgroundColor: "cobalt.white",
        }}
      >
        {/* Prev step. ----------------------------------------------------- */}
        <Grid item>
          {activeStep !== 0 && activeStep !== steps.length - 1 ? (
            <CobaltIcon
              data-testid={"StepHorizontalDrawer-prev"}
              name="arrow-left"
              sx={{
                fontSize: "1.5rem",
                p: "0rem",
                m: "0rem",
                verticalAlign: "middle",
                color: colors.bleuDigital100,
              }}
              onClick={() => {
                onHeader(HeaderActions.GOBACK);
              }}
            />
          ) : (
            <CobaltIcon
              name="arrow-left"
              sx={{
                fontSize: "1.5rem",
                p: "0rem",
                m: "0rem",
                verticalAlign: "middle",
                color: colors.white,
              }}
            />
          )}
        </Grid>

        {/* Step Name. ----------------------------------------------------- */}
        <Grid item xs>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              p: "0rem",
              m: "0rem",
              color: "cobalt.bleuDigital100",
            }}
          >
            {steps && steps[activeStep]?.title}
          </Typography>
        </Grid>

        {/* Exit. ---------------------------------------------------------- */}
        <Grid item>
          <CobaltIcon
            data-testid={"StepHorizontalDrawer-exit"}
            name="checkbox-x"
            sx={{
              fontSize: "1.5rem",
              p: "0rem",
              m: "0rem",
              verticalAlign: "middle",
              color: colors.bleuDigital100,
            }}
            onClick={() => {
              onHeader(HeaderActions.EXIT);
            }}
          />
        </Grid>
      </Grid>

      {/* Stepper. --------------------------------------------------------- */}
      <Grid
        item
        sx={{
          backgroundColor: "cobalt.light",
          width: "100%",
        }}
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          orientation="horizontal"
          sx={{
            width: "100%",
            m: "0rem",
            p: "0rem",
            alternativeLabel: {
              top: 10,
              left: "calc(-50% + 7px)",
              right: "calc(50% + 7px)",
            },
            ".MuiStepConnector-lineHorizontal": {
              marginTop: "-1.688rem",
            },
          }}
        >
          {steps &&
            steps.map((label) => (
              <Step key={label.id}>
                <StepLabel>&nbsp;</StepLabel>
              </Step>
            ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};
export default StepHorizontalDrawer;
