import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Dialog, theme } from "@docaposte-agility/da-design-system";

// Services/Utils. -------------------------------------------------------------
import { parseContent } from "utils/parseContent";

// =============================================================================
// DeleteConfirm...
// =============================================================================
interface DeleteConfirmProps {
  open: boolean;
  title: string;
  message: string;
  buttonNo: string;
  buttonYes: string;
  onResponse: (confirmed: boolean) => void;
}
const DeleteConfirm: React.FC<DeleteConfirmProps> = ({
  open,
  title,
  message,
  buttonNo,
  buttonYes,
  onResponse,
}) => {
  // Members. ------------------------------------------------------------------
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  // Render. -------------------------------------------------------------------
  return (
    <Dialog
      data-testid="DeleteConfirm-modal"
      open={open}
      fullWidth={mobile}
      sx={{ p: "0rem", m: "0rem" }}
      PaperProps={{
        sx: {
          position: mobile ? "fixed" : "",
          bottom: 0,
          left: 0,
          m: 0,
          p: 0,
          width: "100%",
        },
      }}
      onClose={() => {
        onResponse(false);
      }}
    >
      {/* Title. ----------------------------------------------------------- */}
      <DialogTitle>
        {/* Use div to remove warning "<h4> cannot appear as a child of <h2>"" */}
        <div>
          <Typography
            variant="h4"
            sx={{ textAlign: "start", color: "cobalt.ultramarine" }}
          >
            {parseContent(title)}
          </Typography>
        </div>
      </DialogTitle>
      <Divider sx={{ backgroundColor: "cobalt.grey10" }} />

      {/* Message. --------------------------------------------------------- */}
      <DialogContent
        sx={{ textAlign: "start", p: mobile ? "1rem" : "0rem", m: "0rem" }}
      >
        <Typography
          variant="body1"
          sx={{
            whiteSpace: "pre-line",
            mt: "32px",
            mb: "10px",
            color: "cobalt.ultramarine",
          }}
        >
          {parseContent(message)}
        </Typography>
      </DialogContent>
      <Divider sx={{ backgroundColor: "cobalt.grey10" }} />

      {/* Action. ---------------------------------------------------------- */}
      <DialogActions>
        <Grid
          container
          direction={mobile ? "column-reverse" : "row"}
          justifyContent="flex-end"
          alignItems="center"
          sx={{ m: "0rem", p: "0rem" }}
        >
          {/* confirm no. ---------------------------------------------------- */}
          <Grid
            item
            sx={{
              m: "0.5rem 0rem",
              p: "0rem",
              width: mobile ? "100%" : "auto",
            }}
          >
            <Button
              data-testid="DeleteConfirm-modal-NO"
              fullWidth={mobile}
              variant="text"
              sx={{ mr: "5px" }}
              onClick={() => {
                onResponse(false);
              }}
            >
              {buttonNo}
            </Button>
          </Grid>

          {/* confirm yes. --------------------------------------------------- */}
          <Grid
            item
            sx={{
              m: "0rem",
              p: "0rem",
              width: mobile ? "100%" : "auto",
            }}
          >
            <Button
              data-testid="DeleteConfirm-modal-YES"
              variant="contained"
              fullWidth={mobile}
              sx={{
                ml: "5px",
                backgroundColor: "cobalt.redText",
                color: "cobalt.white",
                ":hover": {
                  backgroundColor: "cobalt.redIcon",
                  color: "cobalt.redBackground",
                },
              }}
              onClick={() => {
                onResponse(true);
              }}
            >
              {buttonYes}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteConfirm;
