import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// Services/Utils. -------------------------------------------------------------
import keycloak from "config/keycloak";

// =============================================================================
// usePostRejectSurvey...
// =============================================================================
export const usePostRejectSurvey = (
  config: UseMutationOptions<
    { data: any },
    AxiosError,
    {
      surveyId: string;
    }
  > = {},
) => {
  return useMutation(
    ({ surveyId }) =>
      Axios.post(
        "/V2/surveys/" + surveyId + "/reject",
        {},
        {
          baseURL: window.__ENV__.REACT_APP_BO_AUTH_URL,
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
          params: {},
        },
      ),
    {
      ...config,
    },
  );
};
