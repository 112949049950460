import React, {
  PropsWithChildren,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import Keycloak, { KeycloakInitOptions } from "keycloak-js";

// Components. -----------------------------------------------------------------
import {
  KeycloakContext,
  KeycloakContextType,
} from "app/context/KeycloakContext";

export const useKeycloak = (): KeycloakContextType =>
  useContext(KeycloakContext);

// =============================================================================
// KeycloakProvider...
// =============================================================================
type KeycloakProviderProps = {
  authClient: Keycloak;
  initOptions: KeycloakInitOptions;
  onEvent: (
    e: "onReady" | "onAuthSuccess" | "onAuthRefreshSuccess" | "onAuthLogout",
  ) => void;
  onTokens: (e: "onTokenExpired") => void;
  onError: (
    e: "onAuthError" | "onAuthRefreshError" | "onInitError",
    error?: unknown,
  ) => void;
};
export const KeycloakProvider = (
  props: PropsWithChildren<KeycloakProviderProps>,
): JSX.Element => {
  const { authClient, initOptions, onEvent, onError, onTokens, children } =
    props;
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  // Refresh Token. ------------------------------------------------------------
  const refreshToken = async () => {
    try {
      await authClient.updateToken(5);
    } catch (e) {
      authClient.logout();
    }
  };

  // onEvent callback. ---------------------------------------------------------
  authClient.onReady = (): void => onEvent("onReady");
  authClient.onAuthSuccess = (): void => onEvent("onAuthSuccess");
  authClient.onAuthRefreshSuccess = (): void => onEvent("onAuthRefreshSuccess");
  authClient.onAuthLogout = (): void => onEvent("onAuthLogout");

  // onTokens. -----------------------------------------------------------------
  authClient.onTokenExpired = (): void => {
    onTokens("onTokenExpired");
    refreshToken();
  };

  // onError callback. ---------------------------------------------------------
  authClient.onAuthError = (e): void => onError("onAuthError", e);
  authClient.onAuthRefreshError = (): void => onError("onAuthRefreshError");

  // Hooks/callback. -----------------------------------------------------------
  const initKeycloak = useCallback(() => {
    const init = async () => {
      try {
        const authenticated = await authClient.init(initOptions);
        setIsAuthenticated(authenticated);
      } catch (e) {
        onError("onInitError", e);
      } finally {
        setInitialized(true);
      }
    };

    if (!initialized) {
      return init();
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authClient]);
  useEffect(() => {
    initKeycloak();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = React.useMemo(
    () => ({
      keycloak: authClient,
      initialized,
      isAuthenticated,
    }),
    [authClient, initialized, isAuthenticated],
  );

  // Render. -------------------------------------------------------------------
  return (
    <KeycloakContext.Provider value={value}>
      {children}
    </KeycloakContext.Provider>
  );
};
