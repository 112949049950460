import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// =============================================================================
// executeRequestInterceptor...
// =============================================================================
export const executeRequestInterceptor = () => {
  Axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      return {
        ...config,
        headers: {
          ...config.headers,
        },
      };
    },
    (error) => Promise.reject(error),
  );
};

// =============================================================================
// executeResponseInterceptor...
// =============================================================================
export const executeResponseInterceptor = () => {
  Axios.interceptors.response.use((response: AxiosResponse) => {
    return response;
  });
};
