import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// Services/Utils. -------------------------------------------------------------
import keycloak from "config/keycloak";

// =============================================================================
// useGetSurvey...
// =============================================================================
export const useGetSurvey = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    {
      surveyId: string;
    }
  > = {},
) => {
  return useMutation(
    ({ surveyId }) =>
      Axios.get("/V2/surveys/" + surveyId, {
        baseURL: window.__ENV__.REACT_APP_BO_AUTH_URL,
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
        params: {
          // ici TVA ici a ajouter quand mise a jour backend <view: "medic"> uniquement en mode light
          // view: "medic"
        },
      }),
    {
      ...config,
    },
  );
};
