import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import { theme } from "@docaposte-agility/da-design-system";

// Components. -----------------------------------------------------------------
import { contextECT } from "app/context/Context";
import { useKeycloak } from "app/context/KeycloakProvider";

import Backdrop from "app/components/common/Backdrop";
import DeleteConfirm from "app/components/common/modal/DeleteConfirm";
import Header from "app/components/common/Header";
import StepHorizontalDrawer from "app/components/common/drawer/StepHorizontalDrawer";
import StepVerticalDrawerTrial from "app/components/common/drawer/StepVerticalDrawerTrial";
import Toaster from "app/components/common/Toaster";

import ConsentValidEnd from "app/components/user/ConsentValid/step6/ConsentValidEnd";
import ConsentValidSign from "app/components/user/ConsentValid/step5/ConsentValidSign";

// Services/Utils. -------------------------------------------------------------
import { useGetSurvey } from "services/survey/useGetSurvey.hook";
import { useGetUserIP } from "services/user/useGetUserIP.hook";

import { dateFormat } from "utils/date/dateFormat";
import { dateLog } from "utils/date/dateLog";
import { dateMonthToString } from "utils/date/dateMonthToString";
import { dateNow } from "utils/date/dateNow";
import { setStorageValue, getStorageValue } from "utils/memoryStorage";

// Types/Const. ----------------------------------------------------------------
import { ConsentValidStepperType } from "types/consent-valid-stepper.type";
import { emptyToaster, ToasterType } from "types/toaster.type";
import { HeaderActions } from "types/header.enum";
import { UserStep } from "types/user-step.enum";

// =============================================================================
// ConsentValidTrial...
// =============================================================================
interface ConsentValidProps {}
const ConsentValidTrial: React.FC<ConsentValidProps> = () => {
  // Members. ------------------------------------------------------------------
  const { setContextSurvey } = React.useContext(contextECT);
  const [loading, setLoading] = React.useState(false);
  const [toasterMessage, setToasterMessage] =
    React.useState<ToasterType>(emptyToaster);
  const [exitConfirmType, setExitConfirmType] = React.useState("");
  const [steps, setSteps] = React.useState<ConsentValidStepperType[]>([]);
  const [subTitle, setSubTitle] = React.useState("");
  const [consentStatus, setConsentStatus] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(-1);

  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();
  const location = useLocation();
  const { trialId } = useParams();
  const tenant = getStorageValue("tenant") || "";
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [ipClient, setIpClient] = React.useState("");

  // Manage web browser goBack. ------------------------------------------------
  window.history.pushState(null, "", location.pathname);
  window.onpopstate = function () {
    handleConfirmExit(true);
    window.history.go(1);
  };

  // Functions. ----------------------------------------------------------------
  const initSurvey = (resDataSurvey) => {
    const curSurvey = {
      id: resDataSurvey.id || "",
      side: resDataSurvey.side || false,
      radius: resDataSurvey.radius || false,
      surveyTypeId: resDataSurvey.survey_type.id || "",
      title: resDataSurvey.title || "",
      is_signed: resDataSurvey.is_signed || false,
      status: resDataSurvey.status || "",
      description: resDataSurvey.comment || "",
      survey_drawing_id: resDataSurvey.survey_drawing_id || "",
      survey_attachment_id: resDataSurvey.survey_attachment_id || "",
      intervention:
        {
          id: resDataSurvey.intervention.id,
          date: resDataSurvey.intervention.date,
          date_consult: resDataSurvey.intervention.date_consult,
        } || {},
      referent:
        {
          id: resDataSurvey.referent.id,
          title: resDataSurvey.referent.title,
          firstname: resDataSurvey.referent.firstname,
          lastname: resDataSurvey.referent.lastname,
        } || {},
      steps: resDataSurvey.steps.map((step) => ({
        id: step.id,
        title: step.translation.title,
        questions: step.questions.map((question) => ({
          id: question.id || "",
          type_id: question.type_id || "",
          step_id: question.step_id || "",
          answer: question.answer,
          asked_more_info: question.asked_more_info || null,
          question: question.translation.question || "",
          data: question.translation.data || {},
          desc: question.translation.desc || "",
          rewording: question.translation.rewording || "",
          translation: {
            id: question.translation.id || "",
            created_at: question.translation.created_at || "",
            updated_at: question.translation.updated_at || "",
            language_id: question.translation.language_id || "",
            question_id: question.translation.question_id || "",
            question: question.translation.question || "",
            data: question.translation.data || "",
            desc: question.translation.desc || "",
            rewording: question.translation.rewording || "",
            texte_pdf: question.translation.texte_pdf || "",
            incompris: question.translation.incompris || "",
          },
        })),
      })),
      date_start: dateNow(),
      specialite: { type: 0, specialite: 0 },
      translation: {
        country_code_flag: "FR",
        language_id: 1,
      },
    };
    setContextSurvey(curSurvey);
    return curSurvey;
  };

  const initSteps = (curSurvey) => {
    setLoading(false);
    const listStep2details = curSurvey.steps.map((step) => step.title);
    let nuStep: number = 1;
    const curSteps: ConsentValidStepperType[] = [];
    while (
      t("pro.consentValid.step" + nuStep + ".title") !==
      "pro.consentValid.step" + nuStep + ".title"
    ) {
      if (nuStep === 3 && listStep2details.length > 0) {
        // eslint-disable-next-line no-loop-func
        listStep2details.forEach((element, index) => {
          curSteps.push({
            id: "pro.consentValid.step" + nuStep + "." + (index + 1),
            title: element,
          });
        });
      } else {
        if (nuStep === 4) {
          setStorageValue("step", nuStep);
          setActiveStep(nuStep);
        }
        curSteps.push({
          id: "pro.consentValid.step" + nuStep,
          title: t("pro.consentValid.step" + nuStep + ".title"),
        });
      }
      nuStep++;
    }
    setSteps(curSteps);
  };

  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    setStorageValue(
      "route",
      location.pathname.replace(tenant + "/", "") + location.search,
    );
    setActiveStep(-1);
    setConsentStatus("");
    setLoading(true);
    getSurvey({ surveyId: trialId || "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, tenant, trialId]);

  React.useEffect(() => {
    getIP({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Backend. ------------------------------------------------------------------
  const { mutate: getSurvey } = useGetSurvey({
    onSuccess: (data) => {
      console.debug(dateLog("BKO", "useGetSurvey.onSuccess"), data);
      const resDataSurvey = data["data"]["survey"];
      const curSurvey = initSurvey(resDataSurvey);
      setSubTitle(
        curSurvey.title +
          t("user.consentValid.scheduledOn") +
          dateFormat(curSurvey.intervention.date, "dd") +
          " " +
          dateMonthToString(
            curSurvey.intervention.date,
            keycloak?.tokenParsed.locale,
          ) +
          " " +
          dateFormat(curSurvey.intervention.date, "yyyy"),
      );
      initSteps(curSurvey);
    },
    onError: (error) => {
      console.debug(dateLog("BKO", "useGetSurvey.onError"), error);
      setLoading(false);
      setToasterMessage({
        type: "error",
        title: t("message.backendErrorTitle"),
        message: t("message.backendErrorMsg"),
        autoClose: 4000,
      });
    },
  });

  const { mutate: getIP } = useGetUserIP({
    onSuccess: (data) => {
      setIpClient(data["data"].ip);
    },
  });

  // Handlers. -----------------------------------------------------------------
  const handlerHeader = (action: HeaderActions) => {
    // Exit. -----------------------------------------------------------------
    if (action === HeaderActions.EXIT) {
      if (activeStep !== steps.length - 1) {
        setExitConfirmType(HeaderActions.GOBACK);
      } else {
        handleConfirmExit(true);
      }
    }
  };

  const handleNext = (action: UserStep) => {
    switch (action) {
      // Exit. ---------------------------------------------------------------
      case UserStep.EXIT:
        handleConfirmExit(true);
        break;

      // Next step sign. -------------------------------------------------------
      case UserStep.SIGN:
        setConsentStatus(UserStep.SIGN);
        setStorageValue("step", steps.length - 2);
        setActiveStep(steps.length - 2);
        break;

      // Not yet implemented. --------------------------------------------------
      default:
        console.debug(
          dateLog("DBG", "ConsentValidTrial(") +
            JSON.stringify(action) +
            ") ... NOT YET IMPLEMENTED",
        );
    }
  };

  const handleConfirmExit = (confirmed: boolean) => {
    setExitConfirmType("");
    if (confirmed) {
      setStorageValue("step");
      const splitPath = location.pathname.split("/");
      splitPath.pop();
      splitPath.pop();
      navigate(splitPath.join("/") + t("pro.nav.link1.nav4.path"), {
        replace: true,
      });
    }
  };
  // Render. -------------------------------------------------------------------
  return (
    <>
      {/* Backdrop. -------------------------------------------------------- */}
      {loading && <Backdrop loading={loading} />}

      {/* Toaster. --------------------------------------------------------- */}
      {toasterMessage && <Toaster message={toasterMessage} />}

      {/* Consent Valid. --------------------------------------------------- */}
      <Grid
        container
        direction={mobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ height: "100%" }}
      >
        {/* Drawer. -------------------------------------------------------- */}
        {mobile ? (
          <Grid item sx={{ width: "100%" }}>
            <StepHorizontalDrawer
              steps={steps}
              activeStep={3}
              onHeader={handlerHeader}
            />
          </Grid>
        ) : (
          <Grid
            item
            sx={{
              width: "240px",
              position: "fixed",
              top: "0rem",
            }}
          >
            <StepVerticalDrawerTrial
              steps={steps}
              activeStep={activeStep}
              userName={keycloak?.tokenParsed?.name || "xYz"}
            />
          </Grid>
        )}

        {/* Body. ---------------------------------------------------------- */}
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          xs
          sx={{
            m: "0rem",
            p: { xs: "1rem ", sm: "1rem 2rem", md: "1rem 8rem 2rem" },
            backgroundColor: "cobalt.light",
            position: mobile ? "" : "absolute",
            left: mobile ? "0px" : "240px",
            width: mobile ? "100%" : "calc(100% - 240px)",
          }}
        >
          {/* Header. ------------------------------------------------------ */}
          {!mobile && (
            <Grid item sx={{ width: "100%" }}>
              <Header
                exitButton={consentStatus !== UserStep.REJECT}
                title={t("pro.consentValid.title")}
                subTitle={subTitle}
                onHeader={handlerHeader}
              />
            </Grid>
          )}

          {/* Consent. ----------------------------------------------------- */}
          <Grid
            item
            sx={{
              width: "100%",
              backgroundColor: "cobalt.light",
            }}
          >
            {/* Step 6: Visioneuse. ---------------------------------------- */}
            <ConsentValidSign onNext={handleNext} ipClient={ipClient} />

            {/* Step 7: Consentement. -------------------------------------- */}
            {activeStep === steps.length - 1 && (
              <ConsentValidEnd consentStatus={consentStatus} />
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Exit confirm. ---------------------------------------------------- */}
      {exitConfirmType && (
        <DeleteConfirm
          open={exitConfirmType !== ""}
          title={t("message.confirm." + exitConfirmType + "Title")}
          message={t("message.confirm." + exitConfirmType + "Message")}
          buttonNo={t("message.confirm.cancel")}
          buttonYes={t("message.confirm.exit")}
          onResponse={handleConfirmExit}
        />
      )}
    </>
  );
};
export default ConsentValidTrial;
