import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";

// Services/Utils. -------------------------------------------------------------
import getAllObjectKeys from "utils/getAllObjectKeys";
import { getEN, getFR } from "utils/getLanguagesConfig";

// Types/Const. ----------------------------------------------------------------
const nameSpace = "ECT";
const curLang = localStorage.getItem("i18nextLng") || "fr";
export const availableLanguages = {
  fr: "ltr",
  en: "ltr",
};
export const defaultLanguage = "fr";

// =============================================================================
// i18nGetAllKeys...
// =============================================================================
export const i18nGetAllKeys = (rootKey: string) => {
  const fltr = nameSpace + "." + rootKey + ".";
  return getAllObjectKeys(i18n.getDataByLanguage(i18n.language))
    .filter((item) => item.includes(fltr))
    .map((item) => item.replace(fltr, ""));
};
export const i18nGetAllValuesLabels = (rootKey: string) => {
  const fltr = nameSpace + "." + rootKey + ".";
  return getAllObjectKeys(i18n.getDataByLanguage(i18n.language))
    .filter((item) => item.includes(fltr))
    .map((item) => ({
      value: item.replace(fltr, ""),
      label: i18n.t(rootKey + "." + item.replace(fltr, "")),
    }));
};
export const i18nGetAllLabels = (rootKey: string) => {
  const fltr = nameSpace + "." + rootKey + ".";
  return getAllObjectKeys(i18n.getDataByLanguage(i18n.language))
    .filter((item) => item.includes(fltr))
    .map((item) => i18n.t(rootKey + "." + item.replace(fltr, "")));
};
export const i18enGetLanguage = () => {
  return localStorage.getItem("i18nextLng");
};

// =============================================================================
// init...
// =============================================================================
Promise.all([getFR(), getEN()])
  .then(([fr, en]) => {
    i18n
      .use(intervalPlural)
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        debug: process.env.NODE_ENV !== "production",
        resources: { fr, en },
        defaultNS: nameSpace,
        fallbackLng: curLang,
      });
  })
  .catch((error) => {
    console.debug(error);
  });

// =============================================================================
// onLanguageChanged...
// =============================================================================
i18n.on("languageChanged", (lang: string) => {
  localStorage.setItem("i18nextLng", lang);
});

export default i18n;
