// =============================================================================
// SurveyType...
// =============================================================================
export interface SurveyType {
  id: string;
  side: boolean;
  radius: boolean;
  surveyTypeId: string;
  title: string;
  is_signed: boolean;
  status: string;
  description: string;
  survey_drawing_id: string;
  survey_attachment_id: string;
  intervention: SurveyInterventionType;
  referent: SurveyReferentType;
  steps: SurveyStepsType[];
  date_start: string;
  specialite: SurveySpecialiteInterventionType;
  error?: {
    title: boolean;
    type: boolean;
    speciality: boolean;
    stepsEmpty: boolean;
  };
  translation: {
    language_id: number;
    country_code_flag: string;
  };
  survey_pdfs?: {}[];
  isEdit?: boolean;
}
export interface SurveyInterventionType {
  id: string;
  date: string;
  date_consult:string;
}
export interface SurveyReferentType {
  id: string;
  title: string;
  firstname: string;
  lastname: string;
}
export interface SurveyStepsType {
  id: string;
  title: string;
  desc?: string;
  questions: SurveyQuestionsType[];
  translation?:{
    id:string;
    created_at:string;
    updated_at:string;
    language_id: string;
    question_id: string;
    question: string;
    data: any;
    desc: string;
    rewording: string;
    texte_pdf: string;
    incompris: string;
    "__typename": "questions_translations"
  };
  errorTitle?: boolean;
  error?: boolean;
}
export interface SurveyQuestionsType {
  id: string;
  type_id: number;
  answer: string;
  asked_more_info: boolean;
  question: string;
  data: SurveyQuestionsDataType;
  desc: string;
  rewording: string;
  error?: boolean;
}
export interface SurveyQuestionsDataType {
  first?: string;
  second?: string;
  list?: SurveyQuestionsDataListType[];
  oid?: number;
  pdf?: File;
  isPDFType?: boolean;
  answer?: string;
}
export interface SurveyQuestionsDataListType {
  label: string;
  value: string;
}
export interface SurveySpecialiteInterventionType {
  type: number;
  specialite: number;
}

//#region  Graphql
export interface SurveyTypeGraphqlList {
  id: number;
  survey_types: SurveyTypeGraphqlsurveyTypes[];
  survey_specialities: SurveySpecialiteGraphqlList[];
}

export interface SurveySpecialiteGraphqlList {
  id: number;
  specialities_translations: SurveySpecialiteTranslationGraphqlList[];
}

export interface SurveySpecialiteTranslationGraphqlList {
  value: string;
}

export interface SurveyTypeGraphqlsurveyTypes {
  id: number;
  survey_types_translations: SurveyTypeGraphqlsurveyTypesTranslations[];
}

export interface SurveyTypeGraphqlsurveyTypesTranslations {
  id: number;
  title: string;
}
//#endregion

export const emptySurvey = {
  id: "-1",
  side: false,
  radius: false,
  surveyTypeId: "",
  title: "",
  is_signed: false,
  status: "sent",
  description: "",
  survey_drawing_id: "",
  survey_attachment_id: "",
  intervention: {} as SurveyInterventionType,
  referent: {} as SurveyReferentType,
  steps: [] as SurveyStepsType[],
  date_start: "",
  specialite: {} as SurveySpecialiteInterventionType,
  translation: {
    country_code_flag: "FR",
    language_id: 1,
  },
  survey_pdfs: [{}],
  isEdit: false,
};
