import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemText,
  Grid,
  Divider,
  Stack,
  Tooltip,
} from "@mui/material";
import { CobaltIcon, colors } from "@docaposte-agility/da-design-system";

// Components. -----------------------------------------------------------------
import { useKeycloak } from "app/context/KeycloakProvider";
import { contextProECT } from "app/context/ContextPro";

import DeleteConfirm from "app/components/common/modal/DeleteConfirm";
import ChangeTeam from "app/components/common/modal/ChangeTeam";
import ChangeHospital from "app/components/common/modal/ChangeHospital";

// Services/Utils. -------------------------------------------------------------
import stringAvatar from "utils/stringAvatar";
import { getStorageValue, setStorageValue } from "utils/memoryStorage";
import { haveMedicTeamType } from "utils/haveMedicTeamType";

// Types/Const. ----------------------------------------------------------------
import { NawDarwerLinkType } from "types/nav-drawer-link.type";
import { MedicRoles, MedicTypes } from "types/roles.enum";
import { haveMedicRoles } from "utils/haveMedicRoles";

// =============================================================================
// NavDrawerFooter...
// =============================================================================
interface NavDrawerFooterProps {
  userName: string;
  mode: string;
  selected: string;
}
const NavDrawerFooter: React.FC<NavDrawerFooterProps> = ({
  userName,
  mode,
  selected,
}) => {
  // Members. ------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openChangeTeam, setOpenChangeTeam] = React.useState(false);
  const [openChangeHospital, setOpenChangeHospital] = React.useState(false);
  const [navLink, setNavLink] = React.useState<NawDarwerLinkType[]>([]);
  const [teams, setTeams] = React.useState<
    { label: string; value: number; isClinicalStudy: boolean }[]
  >([]);
  const {
    contextMedicalTeams,
    contextActiveMedicalTeam,
    setContextActiveMedicalTeam,
    contextActiveClinic,
    contextMedic,
    setContextActiveClinic,
  } = React.useContext(contextProECT);

  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();
  const location = useLocation();
  const tenant = String(getStorageValue("tenant"));

  const activated = true;

  // MOCK vaiting for backend call. ********************************************
  const hospitals = [
    {
      label: "CHU Lille",
      value: 0,
    },
    {
      label: "CH de Roubaix",
      value: 1,
    },
    {
      label: "CH Armentières",
      value: 2,
    },
  ];
  // MOCK vaiting for backend call. ********************************************

  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    const formattedTeams = contextMedicalTeams.map((team, index) => {
      return {
        label: team.title,
        value: index,
        isClinicalStudy: team.is_clinical_study,
      };
    });
    setTeams(formattedTeams);
  }, [contextMedicalTeams]);

  React.useEffect(() => {
    let nuNavLink: number = 1;
    const curNavLink: NawDarwerLinkType[] = [];
    if (mode === "pro") {
      while (
        t(mode + ".nav.menu.link1.nav" + nuNavLink + ".name") !==
        mode + ".nav.menu.link1.nav" + nuNavLink + ".name"
      ) {
        curNavLink.push({
          id: mode + "nav.link1.nav" + nuNavLink,
          icon: t(mode + ".nav.menu.link1.nav" + nuNavLink + ".icon"),
          name: t(mode + ".nav.menu.link1.nav" + nuNavLink + ".name"),
          path: t(mode + ".nav.menu.link1.nav" + nuNavLink + ".path"),
        });
        nuNavLink++;
      }
    } else {
      curNavLink.push({
        id: mode + ".nav.menu.logout",
        icon: t(mode + ".nav.menu.logout.icon"),
        name: t(mode + ".nav.menu.logout.name"),
        path: t(mode + ".nav.menu.logout.path"),
      });
    }
    setNavLink(curNavLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Functions. ----------------------------------------------------------------
  const canDisplay = (path: string) => {
    if (path !== "/logout" && path !== "/profile" && path !== "/changeTeam") {
      if (
        contextActiveMedicalTeam !== -1 &&
        haveMedicTeamType(
          contextMedicalTeams[contextActiveMedicalTeam],
          MedicTypes.HOSPIT,
        )
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  // Handlers. -----------------------------------------------------------------
  const handleConfirm = (confirmed: boolean) => {
    setOpenConfirm(false);
    if (confirmed) {
      setStorageValue("connected");
      setStorageValue("route");
      setStorageValue("medicTeam");
      setStorageValue("clinic");
      keycloak.logout();
      if (mode === "pro") {
        navigate("/" + tenant + "/pro/dashboard", { replace: true });
      } else {
        navigate("/" + tenant + "/consentement", { replace: true });
      }
    }
  };
  const handleChangeTeam = (index: number) => {
    setOpenChangeTeam(false);
    if (index !== -1) {
      setContextActiveMedicalTeam(index);
      if (haveMedicRoles(contextMedic.medic, MedicRoles.INVESTIGATEUR)) {
        if (contextMedicalTeams[index].is_clinical_study) {
          if (location.pathname.includes("modelMedical")) {
            const splitPath = location.pathname.split("/");
            const index = splitPath.indexOf("modelMedical");
            if (index !== -1) {
              const updatedSplitPath = splitPath.slice(0, index);
              navigate(updatedSplitPath.join("/") + "/dashboard", {
                replace: true,
              });
            }
          } else if (location.pathname.includes("trial")) {
            const splitPath = location.pathname.split("/");
            const index = splitPath.indexOf("trial");
            if (index !== -1) {
              const updatedSplitPath = splitPath.slice(0, index);
              navigate(updatedSplitPath.join("/") + location.search, {
                replace: true,
              });
            }
          }
        } else {
          if (location.pathname.includes("trial")) {
            const splitPath = location.pathname.split("/");
            const index = splitPath.indexOf("trial");
            if (index !== -1) {
              const updatedSplitPath = splitPath.slice(0, index);
              navigate(updatedSplitPath.join("/") + "?mode=medical", {
                replace: true,
              });
            }
          } else if (location.pathname.includes("patients")) {
            const splitPath = location.pathname.split("/");
            const index = splitPath.indexOf("patients");
            if (index !== -1) {
              const updatedSplitPath = splitPath.slice(0, index);
              navigate(updatedSplitPath.join("/") + "/dashboard", {
                replace: true,
              });
            }
          }
        }
      }

      if (haveMedicRoles(contextMedic.medic, MedicRoles.PROMOTEUR)) {
        if (contextMedicalTeams[index].is_clinical_study) {
          if (location.pathname.includes("trial")) {
            const splitPath = location.pathname.split("/");
            const index = splitPath.indexOf("trial");
            if (index !== -1) {
              const updatedSplitPath = splitPath.slice(0, index);
              navigate(updatedSplitPath.join("/") + location.search, {
                replace: true,
              });
            }
          } else if (location.pathname.includes("centre")) {
            const splitPath = location.pathname.split("/");
            const index = splitPath.indexOf("centre");
            if (index !== -1) {
              const updatedSplitPath = splitPath.slice(0, index);
              navigate(
                updatedSplitPath.join("/") + "?mode=centresInvestigateurs",
                {
                  replace: true,
                },
              );
            }
          }
        }
      }
    }
  };

  const handleChangeHospital = (index: number) => {
    setOpenChangeHospital(false);
    if (index !== -1) {
      setContextActiveClinic(index);
    }
  };

  const handleLink = (curSelected: boolean, path: string) => {
    setAnchorEl(null);
    if (!curSelected && activated) {
      if (path === "/logout") {
        setOpenConfirm(true);
      } else if (path === "/changeTeam") {
        setOpenChangeTeam(true);
      } else if (path === "/changeHospital") {
        setOpenChangeHospital(true);
      } else {
        const splitPath = location.pathname.split("/");
        splitPath.pop();
        navigate(splitPath.join("/") + path, { replace: true });
      }
    }
  };

  // Render. -------------------------------------------------------------------
  return (
    <Grid
      sx={{
        p: "0rem",
        m: "0.5rem 1rem",
        height: "28px",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      {/* Avatar. ------------------------------------------------------ */}
      <IconButton
        data-testid={"NavDrawerFooter-menu-icon"}
        sx={{ p: 0, m: 0 }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Avatar
          sx={{
            backgroundColor: "cobalt.userBlue",
            width: "32px",
            height: "32px",
            fontSize: "14px",
          }}
          {...stringAvatar(userName)}
        />
      </IconButton>

      {/* UserName. ---------------------------------------------------- */}
      <Tooltip title={userName} placement="right" arrow>
        <Typography
          data-testid={"NavDrawerFooter-menu"}
          variant="h4"
          noWrap
          sx={{
            p: "0px 12px",
            cursor: "pointer",
            color: "cobalt.bleuDigital100",
            textOverflow: "ellipsis",
            maxWidth: "200px",
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {userName}
        </Typography>
      </Tooltip>

      {/* Menu. ---------------------------------------------------------*/}
      <Menu
        elevation={3}
        anchorEl={anchorEl}
        open={openMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "long-button",
          style: {
            borderRadius: "6px",
            border: `1px solid ${colors.grey10}`,
          },
        }}
        onClick={(e) => setAnchorEl(null)}
      >
        {navLink.length > 0 &&
          navLink.map((nav, index) => (
            <Stack key={index}>
              {canDisplay(nav.path) && (
                <>
                  <MenuItem
                    data-testid={"NavDrawerFooter-menu-" + nav.path}
                    sx={{
                      borderRadius: "6px",
                      height: "44px",
                      ml: "8px",
                      mr: "8px",
                    }}
                    onClick={() => handleLink(selected === nav.name, nav.path)}
                  >
                    <Typography
                      sx={{
                        p: "3px 5px 0px 5px",
                        color:
                          nav.path === "/logout"
                            ? "cobalt.redText"
                            : "cobalt.ultramarine",
                      }}
                    >
                      <CobaltIcon name={nav.icon} fontSize="16px" />
                    </Typography>
                    <ListItemText>
                      <Typography
                        variant="h5"
                        sx={{
                          color:
                            nav.path === "/logout"
                              ? "cobalt.redText"
                              : "cobalt.ultramarine",
                        }}
                      >
                        {nav.name}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                  {index + 1 !== navLink.length && (
                    <Divider
                      sx={{
                        m: "0rem",
                        p: "0rem",
                        backgroundColor: "cobalt.grey10",
                      }}
                    />
                  )}
                </>
              )}
            </Stack>
          ))}
      </Menu>

      {/* Log out confirm. ------------------------------------------------- */}
      {openConfirm && (
        <DeleteConfirm
          open={openConfirm}
          title={t("message.confirm.exitTitle")}
          message={t("message.confirm.exitMessage")}
          buttonNo={t("message.confirm.cancel")}
          buttonYes={t("message.confirm.exit")}
          onResponse={handleConfirm}
        />
      )}

      {/* Changer d'équipe. ------------------------------------------------- */}
      {openChangeTeam && (
        <ChangeTeam
          open={openChangeTeam}
          title={t("pro.nav.menu.message.changeTeam.title")}
          teams={teams}
          onResponse={handleChangeTeam}
          activeTeam={contextActiveMedicalTeam}
        />
      )}

      {/* Changer d'établissement. ------------------------------------------ */}
      {openChangeHospital && (
        <ChangeHospital
          open={openChangeHospital}
          title={t("pro.nav.menu.message.changeHospital.title")}
          hospitals={hospitals}
          onResponse={handleChangeHospital}
          activeHospital={contextActiveClinic}
        />
      )}
    </Grid>
  );
};
export default NavDrawerFooter;
