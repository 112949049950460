import { Navigate, useParams } from "react-router-dom";

// Components. -----------------------------------------------------------------
import { useKeycloak } from "app/context/KeycloakProvider";

// Services/Utils. -------------------------------------------------------------
import { setStorageValue } from "utils/memoryStorage";

// Types/Const. ----------------------------------------------------------------
import { Roles } from "types/roles.enum";

// =============================================================================
// PrivateRoute...
// =============================================================================
export function PrivateRoute({ role, children }) {
  // Members. ------------------------------------------------------------------
  const { keycloak } = useKeycloak();
  const params = useParams();

  // Render. -------------------------------------------------------------------
  if (keycloak?.authenticated) {
    if (role !== Roles.NONE) {
      if (keycloak.hasResourceRole(role)) {
        return children;
      } else {
        setStorageValue("route");
        return <Navigate to={"/" + params.tenant} />;
      }
    } else {
      return children;
    }
  } else {
    return <Navigate to={"/" + params.tenant} />;
  }
}
