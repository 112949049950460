// =============================================================================
// MedicType...
// =============================================================================
export interface MedicType {
  success: boolean;
  medic: MedicMedicType;
  user: MedicUserType;
  medicalTeams: MedicalTeamsType[];
  clinics: ClinicsType[];
  trials: TrialsType[];
}

export interface MedicMedicType {
  id: number;
  gender: string;
  firstname: string;
  lastname: string;
  medicSpecialityIds: number[];
  title: string;
  country: string;
  rpps_number: string;
  phone_number: string;
  is_premium: boolean;
  is_secretary: boolean;
  notif_mail: boolean;
  notif_sms: boolean;
  email: string;
  role: null;
}

export interface MedicUserType {
  id: number;
  username: string;
  language_id: number;
}

export interface MedicalTeamsType {
  id: number;
  title: string;
}

export interface ClinicsType {
  id: number;
  title: string;
}

export interface TrialsType {
  id: number;
  title: string;
}

export const emptyMedic = {
  success: false,
  medic: {
    id: 0,
    gender: "male",
    firstname: "",
    lastname: "",
    medicSpecialityIds: [] as number[],
    title: "",
    country: "",
    rpps_number: "",
    phone_number: "",
    is_premium: false,
    is_secretary: false,
    notif_mail: false,
    notif_sms: false,
    email: "",
    role: null,
  },
  user: {
    id: 0,
    username: "",
    language_id: 1,
  },
  medicalTeams: [] as MedicalTeamsType[],
  clinics: [] as ClinicsType[],
  trials: [] as TrialsType[],
};
