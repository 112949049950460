import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useKeycloak } from "app/context/KeycloakProvider";

// Components. -----------------------------------------------------------------
import Accueil from "app/pages/Accueil";
import Backdrop from "app/components/common/Backdrop";

// Services/Utils. -------------------------------------------------------------
import { getStorageValue, setStorageValue } from "utils/memoryStorage";
import { Roles } from "types/roles.enum";

// Types/Const. ----------------------------------------------------------------
const REACT_APP_FRONT_BASE_URL = window.__ENV__.REACT_APP_FRONT_BASE_URL;
const REACT_APP_LOGIN = window.__ENV__.REACT_APP_LOGIN;

// =============================================================================
// Login...
// =============================================================================
const Login = () => {
  // Members. ------------------------------------------------------------------
  const { keycloak } = useKeycloak();
  const params = useParams();

  // Handlers. -----------------------------------------------------------------
  const handleConnect = () => {
    keycloak.login({
      redirectUri: REACT_APP_FRONT_BASE_URL + params.tenant,
      idpHint: params.tenant,
    });
    setStorageValue("tenant", params.tenant);
  };
  const handleLogout = () => {
    keycloak.logout();
  };

  // Render. -------------------------------------------------------------------
  if (!params?.tenant) {
    if (getStorageValue("tenant") !== null) {
      return <Navigate to={"/" + getStorageValue("tenant")} />;
    } else {
      setStorageValue("tenant", REACT_APP_LOGIN);
      return <Navigate to={"/" + REACT_APP_LOGIN} replace={true} />;
    }
  } else {
    if (keycloak?.authenticated) {
      // Utilisateur. ----------------------------------------------------------
      if (keycloak.hasResourceRole(Roles.PATIENT)) {
        if (getStorageValue("route") !== null) {
          return (
            <Navigate
              to={"/" + params.tenant + getStorageValue("route")}
              replace={true}
            />
          );
        } else {
          return (
            <Navigate
              to={"/" + params.tenant + "/consentement"}
              replace={true}
            />
          );
        }
      }

      // Pro. ------------------------------------------------------------------
      else if (keycloak.hasResourceRole(Roles.MEDIC)) {
        if (getStorageValue("route") !== null) {
          return (
            <Navigate
              to={"/" + params.tenant + getStorageValue("route")}
              replace={true}
            />
          );
        } else {
          return (
            <Navigate
              to={"/" + params.tenant + "/pro/dashboard"}
              replace={true}
            />
          );
        }
      }

      // Other... --------------------------------------------------------------
      else {
        return <Accueil handleConnect={handleLogout} />;
      }
    } else {
      if (getStorageValue("connected")) {
        handleConnect();
        return <Backdrop loading />;
      } else {
        return <Accueil handleConnect={handleConnect} />;
      }
    }
  }
};
export default Login;
