import React from "react";
import { BrowserRouter } from "react-router-dom";
import packageJson from "../../package.json";

// Components. -----------------------------------------------------------------
import { KeycloakProvider } from "app/context/KeycloakProvider";
import Providers from "app/Providers";
import Router from "app/Router";
import ContextProvider from "app/context/Context";
import ContextProProvider from "app/context/ContextPro";

// Services/Utils. -------------------------------------------------------------
import { dateLog } from "utils/date/dateLog";
import keycloak from "config/keycloak";
import { I18nextProvider } from "react-i18next";
import i18n from "config/i18n";

import { getStorageValue, setStorageValue } from "utils/memoryStorage";

// =============================================================================
// App...
// =============================================================================
function App() {
  // save href into StorageValue("route") when they are diff. ------------------
  let toSkip = window.location.origin.length + 1;
  if (
    getStorageValue("tenant") !== null &&
    getStorageValue("tenant") !== undefined
  ) {
    toSkip = toSkip + (getStorageValue("tenant")?.length || 0);
  } else {
    toSkip = toSkip + window.__ENV__.REACT_APP_LOGIN.length;
    setStorageValue("tenant", window.__ENV__.REACT_APP_LOGIN);
  }
  const windowRoute = window.location.href.slice(toSkip);
  if (
    windowRoute.indexOf("#state=") === -1 &&
    windowRoute !== getStorageValue("route") &&
    windowRoute.length > 0
  ) {
    setStorageValue("route", windowRoute);
  }

  // Display versions. ---------------------------------------------------
  if (process.env.NODE_ENV === "development") {
    console.log(
      dateLog("ECT", packageJson.name + " - V" + packageJson.version + ".dev"),
    );
  } else {
    console.debug = (...args) => {};
    console.log(
      dateLog("ECT", packageJson.name + " - V" + packageJson.version),
    );
  }

  // Keycloak events. ----------------------------------------------------------
  const eventLogger = (event: unknown) => {
    console.debug(dateLog("KCK", "onKeycloakEvent"), event);
  };
  const errorLogger = (event: unknown) => {
    console.debug(dateLog("KCK", "onKeycloakError"), event);
  };
  const tokenLogger = (tokens: unknown) => {
    console.debug(dateLog("KCK", "onKeycloakTokens"), tokens);
  };

  // render. -------------------------------------------------------------------
  return (
    <Providers>
      <KeycloakProvider
        authClient={keycloak}
        onEvent={eventLogger}
        onTokens={tokenLogger}
        onError={errorLogger}
        initOptions={{
          checkLoginIframe: false,
          pkceMethod: "S256",
        }}
      >
        <I18nextProvider i18n={i18n}>
          <ContextProProvider>
            <ContextProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </ContextProvider>
          </ContextProProvider>
        </I18nextProvider>
      </KeycloakProvider>
    </Providers>
  );
}

export default App;
