import React from "react";
import {
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Dialog, theme } from "@docaposte-agility/da-design-system";

// Services/Utils. -------------------------------------------------------------
import { parseContent } from "utils/parseContent";
import Field from "app/formField/Field";
import RadioBoxField from "app/formField/RadioBoxField";
import { useForm } from "react-hook-form";

// =============================================================================
// ChangeHospital...
// =============================================================================
interface ChangeHospitalProps {
  open: boolean;
  title: string;
  hospitals: { label: string; value: number }[];
  activeHospital: number;
  onResponse: (index: number) => void;
}
const ChangeHospital: React.FC<ChangeHospitalProps> = ({
  open,
  title,
  hospitals,
  activeHospital,
  onResponse,
}) => {
  // Members. ------------------------------------------------------------------
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  // Form control. -------------------------------------------------------------
  const { control, watch } = useForm<any>({
    defaultValues: {
      label: hospitals[activeHospital].label,
      value: hospitals[activeHospital].value,
    },
  });

  // Render. -------------------------------------------------------------------
  return (
    <Dialog
      data-testid="ChangeHospital-modal"
      open={open}
      fullWidth={mobile}
      sx={{ p: "0rem", m: "0rem" }}
      PaperProps={{
        sx: {
          position: mobile ? "fixed" : "",
          bottom: 0,
          left: 0,
          m: 0,
          p: 0,
          width: "100%",
        },
      }}
      onClose={() => {
        onResponse(-1);
      }}
    >
      {/* Title. ----------------------------------------------------------- */}
      <DialogTitle>
        {/* Use div to remove warning "<h4> cannot appear as a child of <h2>"" */}
        <div>
          <Typography
            variant="h4"
            sx={{ textAlign: "start", color: "cobalt.ultramarine" }}
          >
            {parseContent(title)}
          </Typography>
        </div>
      </DialogTitle>
      <Divider sx={{ backgroundColor: "cobalt.grey10" }} />
      {/* Message. --------------------------------------------------------- */}
      <DialogContent
        sx={{ textAlign: "start", p: mobile ? "1rem" : "0rem", m: "0rem" }}
      >
        <Grid item xs={12} sm={12} sx={{ m: "0rem", p: "0rem" }}>
          <Field
            as={RadioBoxField}
            id="hospital"
            control={control}
            direction="column"
            radioItems={hospitals}
            value={watch("value")}
            onChange={(e, index) => {
              onResponse(index);
            }}
          />
        </Grid>
      </DialogContent>
      <Divider sx={{ backgroundColor: "cobalt.grey10" }} />
    </Dialog>
  );
};
export default ChangeHospital;
