// =============================================================================
// getStorageValue...
// =============================================================================
export const getStorageValue = (item: string) => {
  return sessionStorage.getItem(item);
};

// =============================================================================
// setStorageValue...
// =============================================================================
export const setStorageValue = (
  item: string,
  value?: string | number | boolean,
) => {
  if (value !== undefined) {
    sessionStorage.setItem(item, String(value));
  } else {
    sessionStorage.removeItem(item);
  }
};
