// =============================================================================
// download...
// =============================================================================
export const download = (blob: Blob, filename: string) => {
  const urlBlob = URL.createObjectURL(blob);
  const tmpLink = document.createElement("a");
  tmpLink.href = urlBlob;
  tmpLink.setAttribute("download", `${filename}`);
  tmpLink.setAttribute("type", "application/octet-stream");
  tmpLink.click();
  setTimeout(() => window.URL.revokeObjectURL(urlBlob), 0);
};

// =============================================================================
// downloadPdf...
// =============================================================================
export const downloadPdf = (data: string, pdfName: string) => {
  const blob = new Blob([data], { type: "application/pdf" });
  download(blob, pdfName);
};

// =============================================================================
// downloadPng...
// =============================================================================
export const downloadPng = (data: string, pngName: string) => {
  const blob = new Blob([data], { type: "image/png" });
  download(blob, pngName);
};

// =============================================================================
// formatFilename...
// =============================================================================
export const formatFilename = (filename: string) => {
  if (!filename) {
    return "";
  }
  let formatedFilename = filename.replace(/[/\\<>|:&?*."[\];,]/g, "-");
  filename =
    formatedFilename.length >= 80
      ? `${formatedFilename.slice(0, 77)}...`
      : formatedFilename;
  return filename;
};
