// =============================================================================
// SurveyIntroQuestionType...
// =============================================================================
export interface SurveyIntroQuestionType {
  intro: SurveyIntroQuestionElemType[];
  generality: SurveyIntroQuestionElemType[];
  conclusion: SurveyIntroQuestionElemType[];
}
export interface SurveyIntroQuestionElemType {
  id: string;
  question: string;
  desc: string;
  first_button: string;
  second_button: string;
  rewording: string;
  answer: string;
  asked_more_info: boolean;
}

export const emptySurveyIntroQuestion = {
  generality: [] as SurveyIntroQuestionElemType[],
  intro: [] as SurveyIntroQuestionElemType[],
  conclusion: [] as SurveyIntroQuestionElemType[],
};
