import React from "react";
import { Grid } from "@mui/material";

// Components. -----------------------------------------------------------------
import { contextECT } from "app/context/Context";

import ConsentValidEndReject from "app/components/user/ConsentValid/step6/ConsentValidEnd-reject";
import ConsentValidEndSummary from "app/components/user/ConsentValid/step6/ConsentValidEnd-summary";
import ConsentValidEndNextConsent from "app/components/user/ConsentValid/step6/ConsentValidEnd-nextConsent";

// Services/Utils. -------------------------------------------------------------

// Types/Const. ----------------------------------------------------------------
import { UserStep } from "types/user-step.enum";

// =============================================================================
// ConsentValidEnd...
// =============================================================================
interface ConsentValidEndProps {
  consentStatus: string;
}
const ConsentValidEnd: React.FC<ConsentValidEndProps> = ({ consentStatus }) => {
  // Members. ------------------------------------------------------------------
  const { contextSurveyList } = React.useContext(contextECT);

  // Render. -------------------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{ m: "0rem", p: "0rem" }}
    >
      {/* Reject. ---------------------------------------------------------- */}
      {consentStatus === UserStep.REJECT && <ConsentValidEndReject />}

      {/* Summary. --------------------------------------------------------- */}
      {consentStatus === UserStep.SIGN && (
        <Grid item sx={{ width: "100%", m: "0", p: "0" }}>
          <ConsentValidEndSummary />
        </Grid>
      )}

      {/* Next consent. ---------------------------------------------------- */}
      {consentStatus === UserStep.SIGN && contextSurveyList.length > 0 && (
        <Grid item sx={{ width: "100%", m: "0", p: "0" }}>
          <ConsentValidEndNextConsent />
        </Grid>
      )}
    </Grid>
  );
};
export default ConsentValidEnd;
