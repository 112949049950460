import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Stack, Typography } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

// Types/Const. ----------------------------------------------------------------
import { ToasterType } from "types/toaster.type";
import { parseContent } from "utils/parseContent";

// =============================================================================
// Toaster.
// =============================================================================
interface ToasterProps {
  message: ToasterType;
}
const Toaster: React.FC<ToasterProps> = ({ message }) => {
  // Handlers. -----------------------------------------------------------------
  const handleClose = () => {
    toast.dismiss(message.toastId);
  };

  // Functions. ----------------------------------------------------------------
  const CloseButton = ({ closeToast }) => (
    <CobaltIcon
      onClick={closeToast}
      name="x"
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
      }}
    />
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderToastContent = () => (
    <Stack
      style={{
        background: "cobalt.white",
        paddingLeft: "10px",
        paddingRight: "auto",
      }}
      onClick={handleClose}
    >
      <Stack
        style={{
          paddingTop: "4px",
          paddingBottom: "4px",
          marginBottom: "10px",
        }}
      >
        <Typography variant="h4">{parseContent(message.title)}</Typography>
      </Stack>
      <Typography
        variant="body1"
        sx={{ whiteSpace: "pre-line", pl: "0px", color: "cobalt.lynch" }}
      >
        {parseContent(message.message)}
      </Typography>
      <CloseButton closeToast={handleClose} />
    </Stack>
  );

  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    if (message.type !== "default") {
      toast(renderToastContent, {
        position: toast.POSITION.TOP_RIGHT,
        type: message.type,
        toastId:
          message.toastId !== undefined ? message.toastId : message.message,
        autoClose: message.autoClose,
        hideProgressBar: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  // Render. -------------------------------------------------------------------
  return (
    <ToastContainer
      closeButton={false}
      autoClose={message.autoClose}
      // limit={5}
      closeOnClick={false}
      pauseOnHover={false}
    />
  );
};

export default Toaster;
