import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, useMediaQuery } from "@mui/material";
import { theme } from "@docaposte-agility/da-design-system";

// Components. -----------------------------------------------------------------
import Toaster from "app/components/common/Toaster";
import ConsentBoxWhite from "app/components/common/box/ConsentBoxWhite";

// Services/Utils. -------------------------------------------------------------
import { useGetSurveySign } from "services/survey/useGetSurveySign";

import { dateLog } from "utils/date/dateLog";

// Types/Const. ----------------------------------------------------------------
import { UserStep } from "types/user-step.enum";
import { emptyToaster, ToasterType } from "types/toaster.type";
import { contextECT } from "app/context/Context";
import { ContraliaQuestions } from "types/contralia-questions.type";

const REACT_APP_BO_AUTH_URL = window.__ENV__.REACT_APP_BO_AUTH_URL;

// =============================================================================
// ConsentValidSignDocs...
// =============================================================================
interface ConsentValidSignDocsProps {
  surveyId: string;
  onNext: (action: UserStep) => void;
  ipClient: string;
}
const ConsentValidSignDocs: React.FC<ConsentValidSignDocsProps> = ({
  surveyId,
  onNext,
  ipClient,
}) => {
  // Members. ------------------------------------------------------------------
  const [toasterMessage, setToasterMessage] =
    React.useState<ToasterType>(emptyToaster);
  const [contraliaUrl, setContraliaUrl] = React.useState("");
  const { contextSurvey } = React.useContext(contextECT);

  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  // Hooks. --------------------------------------------------------------------
  React.useEffect(() => {
    getSurveySign({
      surveyId: surveyId,
      startDate: contextSurvey["date_start"],
      endDate: new Date().toISOString(),
      questions: getListQuestions(),
      ip: ipClient,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);
  // Get IP Client

  React.useEffect(() => {
    if (contraliaUrl !== "") {
      const url =
        REACT_APP_BO_AUTH_URL + "V2/surveys/" + surveyId + "/realtime-status";
      const source = new EventSource(url, { withCredentials: false });

      source.onopen = () => {
        console.debug("*** SSE opened!");
      };

      source.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data.status === "accepted") {
          onNext(UserStep.SIGN);
        } else if (data.status === "rejected") {
          onNext(UserStep.EXIT);
        }
      };

      source.onerror = (e) => {
        console.debug("*** SSE Error: ", e);
        source.close();
      };

      source.addEventListener("close", () => {
        source.close();
      });
      return () => {
        source.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contraliaUrl]);

  // Backend. ------------------------------------------------------------------
  const { mutate: getSurveySign } = useGetSurveySign({
    onSuccess: (data) => {
      console.debug(dateLog("BKO", "useGetSurveySign.onSuccess"), data);
      setContraliaUrl(data["data"].signUrl);
    },
    onError: (error) => {
      console.debug(dateLog("BKO", "useGetSurveySign.onError"), error);
      setToasterMessage({
        type: "error",
        title: t("message.backendErrorTitle"),
        message: t("message.backendErrorMsg"),
        autoClose: 4000,
      });
    },
  });

  // Handlers. -----------------------------------------------------------------
  const getListQuestions = () => {
    let listQuestions = {} as unknown as ContraliaQuestions;
    contextSurvey["steps"].forEach((step, index) => {
      step.questions.forEach((question) => {
        listQuestions[question.id] = {
          id: question.id,
          asked_more_info: question.asked_more_info,
          step_id: question.step_id || "",
          type_id: question.type_id || "",
          answer: question.answer || "",
          translation: {
            id: question.translation.id || "",
            created_at: question.translation.created_at || "",
            updated_at: question.translation.updated_at || "",
            language_id: question.translation.language_id || "",
            question_id: question.translation.question_id || "",
            question: question.translation.question || "",
            data: question.translation.data || "",
            desc: question.translation.desc || "",
            rewording: question.translation.rewording || "",
            texte_pdf: question.translation.texte_pdf || "",
            incompris: question.translation.incompris || "",
          },
        };
      });
    });
    return listQuestions;
  };
  // Render. -------------------------------------------------------------------
  return (
    <>
      {/* Toaster. --------------------------------------------------------- */}
      {toasterMessage && <Toaster message={toasterMessage} />}

      {/* Iframe contralia. ------------------------------------------------ */}
      <ConsentBoxWhite title="">
        <Grid
          container
          direction={mobile ? "column" : "row"}
          justifyContent="flex-start"
          alignItems="center"
          columns={12}
          sx={{
            m: "0rem",
            p: "5px",
            width: "100wh",
            height: "calc(100vh - 300px)",
          }}
        >
          <iframe
            title="Prévisualisation du document"
            src={contraliaUrl}
            width="100%"
            height="100%"
            style={{ borderStyle: "none", overflow: "auto" }}
          />
        </Grid>
      </ConsentBoxWhite>
    </>
  );
};
export default ConsentValidSignDocs;
