import React from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";

// Services/Utils. -------------------------------------------------------------
import { getStorageValue } from "utils/memoryStorage";

// =============================================================================
// NotFound...
// =============================================================================
const NotFound = () => {
  // Members. ------------------------------------------------------------------
  let navigate = useNavigate();

  // Handlers. -----------------------------------------------------------------
  const goBack = () => {
    if (getStorageValue("tenant") && getStorageValue("route")) {
      navigate("" + getStorageValue("tenant") + getStorageValue("route"), {
        replace: true,
      });
    } else {
      navigate("/", {
        replace: true,
      });
    }
  };

  // NotFound render. ----------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        background: `url("/images/background.jpg") center center no-repeat fixed`,
        backgroundSize: "cover",
        margin: 0,
        padding: 0,
        outline: 0,
      }}
    >
      {/* Message box. ----------------------------------------------------- */}
      <Paper
        elevation={3}
        sx={{
          width: "500px",
          height: "250px",
          flex: "0 auto",
          p: "30px",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          sx={{ height: "100%" }}
        >
          {/* Title. ------------------------------------------------------- */}
          <Grid item>
            <Typography variant="h1" sx={{ pb: "15px", textAlign: "center" }}>
              {t("message.notFoundTitle")}
            </Typography>
            <Divider />
          </Grid>

          {/* Body. -------------------------------------------------------- */}
          <Grid item>
            <Typography variant="h2" sx={{ textAlign: "center" }}>
              {t("message.notFoundMsg")}
            </Typography>
          </Grid>

          {/* Action. ------------------------------------------------------ */}
          <Grid item container justifyContent="center" alignItems="center">
            <Button
              data-testid="test-goBack"
              onClick={goBack}
              sx={{ textAlign: "center" }}
            >
              {t("message.notFoundButton")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default NotFound;
