import { MedicTypes } from "types/roles.enum";
import { ProMedicalTeamsType } from "types/pro-medical-teams.type";

// =============================================================================
// haveMedicTeamType...
// =============================================================================
export const haveMedicTeamType = (
  medicTeam: ProMedicalTeamsType,
  type: MedicTypes,
) => {
  if (medicTeam !== undefined && medicTeam?.type.indexOf(type) !== -1) {
    return true;
  }
  return false;
};
