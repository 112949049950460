import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

// =============================================================================
// NavDrawerHeader...
// =============================================================================
interface NavDrawerHeaderProps {
  mode: string;
}
const NavDrawerHeader: React.FC<NavDrawerHeaderProps> = ({ mode }) => {
  // Members. ------------------------------------------------------------------
  const { t } = useTranslation();

  // Render. -------------------------------------------------------------------
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        width: "240px",
        p: "1rem 2rem 1rem 1rem",
        m: "0rem",
        backgroundColor: "cobalt.white",
        borderBottom: "1px solid",
        borderColor: "cobalt.grey10",
      }}
    >
      <Typography
        variant="h5"
        color="cobalt.bleuDigital100"
        sx={{
          p: "0rem 1rem",
          m: "0rem",
        }}
      >
        {t(mode + ".nav.title")}
      </Typography>
    </Grid>
  );
};
export default NavDrawerHeader;
