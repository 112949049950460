import * as React from "react";

// Services/Utils. -------------------------------------------------------------
import { dateLog } from "utils/date/dateLog";

// Types/Const. ----------------------------------------------------------------
import { SurveyType, emptySurvey } from "types/survey.type";
import {
  QuestionTypesType,
  emptyQuestionTypes,
} from "types/question-types.type";
import { PatientType, emptyPatient } from "types/patient.type";
import {
  SurveyIntroQuestionType,
  emptySurveyIntroQuestion,
} from "types/survey-intro-question.type";
import { MedicalTeamsType } from "types/medical-teams.type";

// Utils. ----------------------------------------------------------------------
import { setStorageValue } from "utils/memoryStorage";

// exported ECT context. -------------------------------------------------------
export const contextECT = React.createContext({
  contextActiveMedicalTeam: -1,
  contextActiveClinic: -1,
  contextMedicalTeams: [] as MedicalTeamsType[],
  contextSurveyList: [],
  contextSurvey: { id: "" },
  contextPatient: { id: "" },
  contextQuestionTypes: [] as QuestionTypesType[],
  contextIntroQuestion: {} as SurveyIntroQuestionType,
  setContextSurveyList: (data: string[]) => null,
  setContextSurvey: (data: SurveyType) => null,
  setContextPatient: (data: PatientType) => null,
  setContextQuestionTypes: (data: QuestionTypesType[]) => null,
  setContextIntroQuestion: (data: SurveyIntroQuestionType) => null,
  setContextActiveMedicalTeam: (nu: number) => null,
  setContextActiveClinic: (nu: number) => null,
  setContextMedicalTeams: (data: MedicalTeamsType[]) => null,
});

// =============================================================================
// Contexts...
// =============================================================================
interface ContextProviderProps {
  children: React.ReactNode;
}
const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  // ActiveMedicalTeam. --------------------------------------------------------
  const [contextActiveMedicalTeam, setCtxtActiveMedicalTeam] =
    React.useState(-1);
  const setContextActiveMedicalTeam = (index: number) => {
    console.debug(dateLog("CTX", "setContextActiveMedicalTeam(" + index + ")"));
    setStorageValue("medicTeam", index);
    setCtxtActiveMedicalTeam(index);
    return null;
  };

  // ActiveClinic. -------------------------------------------------------------
  const [contextActiveClinic, setCtxtActiveClinic] = React.useState(-1);
  const setContextActiveClinic = (index: number) => {
    console.debug(dateLog("CTX", "setContextActiveClinic(" + index + ")"));
    setStorageValue("clinic", index);
    setCtxtActiveClinic(index);
    return null;
  };

  // MedicalTeams. -------------------------------------------------------------
  const [contextMedicalTeams, setCtxtMedicalTeams] = React.useState<
    MedicalTeamsType[]
  >([]);
  const setContextMedicalTeams = (data: MedicalTeamsType[]) => {
    console.debug(dateLog("CTX", "setContextMedicalTeams()"), data);
    setCtxtMedicalTeams(data);
    return null;
  };

  // Survey. ------------------------------------------------------------------
  const [contextSurvey, setCtxtSurvey] =
    React.useState<SurveyType>(emptySurvey);
  const setContextSurvey = (data: SurveyType) => {
    console.debug(dateLog("CTX", "setContextSurvey()"), data);
    setCtxtSurvey(data);
    return null;
  };
  const [contextSurveyList, setCtxtSurveyList] = React.useState([]);
  const setContextSurveyList = (data) => {
    console.debug(dateLog("CTX", "setContextSurveyList()"), data);
    setCtxtSurveyList(data);
    return null;
  };

  // Patient. ------------------------------------------------------------------
  const [contextPatient, setCtxtPatient] =
    React.useState<PatientType>(emptyPatient);
  const setContextPatient = (data: PatientType) => {
    console.debug(dateLog("CTX", "setContextPatient()"), data);
    setCtxtPatient(data);
    return null;
  };

  // QuestionTypes. --------------------------------------------------------
  const [contextQuestionTypes, setCtxtQuestionTypes] =
    React.useState<QuestionTypesType[]>(emptyQuestionTypes);
  const setContextQuestionTypes = (data: QuestionTypesType[]) => {
    console.debug(dateLog("CTX", "setContextQuestionTypes()"), data);
    setCtxtQuestionTypes(data);
    return null;
  };

  // IntroQuestionType. --------------------------------------------------------
  const [contextIntroQuestion, setCtxtIntroQuestion] =
    React.useState<SurveyIntroQuestionType>(emptySurveyIntroQuestion);
  const setContextIntroQuestion = (data: SurveyIntroQuestionType) => {
    console.debug(dateLog("CTX", "setContextIntroQuestion()"), data);
    setCtxtIntroQuestion(data);
    return null;
  };

  const value = React.useMemo(
    () => ({
      contextActiveMedicalTeam,
      contextActiveClinic,
      contextMedicalTeams,
      contextSurveyList,
      contextSurvey,
      contextPatient,
      contextQuestionTypes,
      contextIntroQuestion,
      setContextActiveMedicalTeam,
      setContextActiveClinic,
      setContextMedicalTeams,
      setContextSurveyList,
      setContextSurvey,
      setContextPatient,
      setContextQuestionTypes,
      setContextIntroQuestion,
    }),
    [
      contextActiveMedicalTeam,
      contextActiveClinic,
      contextMedicalTeams,
      contextSurveyList,
      contextSurvey,
      contextPatient,
      contextQuestionTypes,
      contextIntroQuestion,
    ],
  );
  // Provider. -----------------------------------------------------------------
  return <contextECT.Provider value={value}>{children}</contextECT.Provider>;
};
export default ContextProvider;
