import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// Services/Utils. -------------------------------------------------------------
import keycloak from "config/keycloak";

// Types/Const. ----------------------------------------------------------------
import { ContraliaQuestions } from "types/contralia-questions.type";

// =============================================================================
// useGetSurveySign...
// =============================================================================
export const useGetSurveySign = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    {
      surveyId: string;
      questions: ContraliaQuestions;
      startDate: string;
      endDate: string;
      ip: string;
    }
  > = {},
) => {
  return useMutation(
    ({ surveyId, ip, startDate, endDate, questions }) =>
      Axios.post(
        "/V2/surveys/" + surveyId + "/createSignUrl",
        {
          ip: ip,
          startDate: startDate,
          endDate: endDate,
          questions: questions,
        },
        {
          baseURL: window.__ENV__.REACT_APP_BO_AUTH_URL,
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        },
      ),
    {
      ...config,
    },
  );
};
