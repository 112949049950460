import { MedicRoles } from "types/roles.enum";
import { MedicMedicType } from "types/pro-medic.type";

// =============================================================================
// haveMedicRoles...
// =============================================================================
export const haveMedicRoles = (
  medic: MedicMedicType,
  type: MedicRoles | null,
) => {
  if (type === null) {
    if (Boolean(medic) && medic.role === null) {
      return true;
    }
  } else {
    if (Boolean(medic) && Boolean(medic.role) && medic.role === type) {
      return true;
    }
  }
  return false;
};
