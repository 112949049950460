import * as React from "react";
import { Backdrop as BackdropMat, CircularProgress } from "@mui/material";

// =============================================================================
// Backdrop
// =============================================================================
interface BackdropProps {
  loading: boolean;
}
const Backdrop: React.FC<BackdropProps> = ({ loading }) => {
  return (
    <BackdropMat
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" size="20vh" thickness={1.5} />
    </BackdropMat>
  );
};

export default Backdrop;
