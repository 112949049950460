import Keycloak from "keycloak-js";

// Services/Utils. -------------------------------------------------------------
const KEYCLOAK_URL = window.__ENV__.KEYCLOAK_URL;
const KEYCLOAK_REALM = window.__ENV__.KEYCLOAK_REALM;
const KEYCLOAK_FRONT_CLIENT_ID = window.__ENV__.KEYCLOAK_FRONT_CLIENT_ID;

// =============================================================================
// keycloak...
// =============================================================================
const keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM || "easy-consent",
  clientId: KEYCLOAK_FRONT_CLIENT_ID || "frontend-v2",
});
export default keycloak;
