export enum HeaderActions {
  NONE = "",
  EXIT = "exit",
  GOBACK = "goBack",
  ADDPATIENT = "addPatient",
  ASSIGNCONSENT = "assignConsent",
  ADDMODEL = "addModel",
  ARCHIVE = "archive",
  PREVIEW = "apercu",
  VALIDATE = "validate",
  CANCEL = "cancel",
  CHANGEMODEL = "changeModel",
}
