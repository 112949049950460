// =============================================================================
// dateFormat: imput date format (ISOString): "yyyy-MM-ddThh:mm:ss.sssZ"
// =============================================================================
export const dateFormat = (isoDate: string, format: string) => {
  const date = new Date(isoDate);
  const subDate = {
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
  };
  if (
    !isNaN(
      subDate.M +
        subDate.d +
        subDate.h +
        subDate.m +
        subDate.s +
        date.getFullYear(),
    )
  ) {
    format = format.replace(/(M+|d+|h+|m+|s+)/g, function (val) {
      return ((val.length > 1 ? "0" : "") + subDate[val.slice(-1)]).slice(-2);
    });
    return format.replace(/(y+)/g, function (val) {
      return date.getFullYear().toString().slice(-val.length);
    });
  } else {
    return isoDate;
  }
};
