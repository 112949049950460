// =============================================================================
// LanguageType...
// =============================================================================
export interface LanguageType {
  id: number;
  language_code: string;
  name: string;
  date_format: string;
  country_code_flag: string;
}

export const emptyLanguages = [
  {
    id: 1,
    language_code: "fr-FR",
    name: "French (France)",
    date_format: "dmy",
    country_code_flag: "FR",
  },
];
