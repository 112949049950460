import React from "react";
import { Routes, Route } from "react-router-dom";
import { useKeycloak } from "app/context/KeycloakProvider";

// Components. -----------------------------------------------------------------
import NotFound from "app/pages/NotFound";
import Login from "app/pages/Login";
import Backdrop from "app/components/common/Backdrop";

// Services/Utils. -------------------------------------------------------------
import { PrivateRoute } from "utils/PrivateRoute";

// Types/Const. ----------------------------------------------------------------
import { Roles } from "types/roles.enum";
import ConsentValidTrial from "app/components/user/ConsentValid/ConsentValidTrial";

// Lazy loading. ---------------------------------------------------------------
const Consentement = React.lazy(() => import("app/pages/Consentement"));
const ConsentPro = React.lazy(() => import("app/pages/ConsentPro"));
const Connexion = React.lazy(() => import("app/pages/Connexion"));

// =============================================================================
// Router...
// =============================================================================
const Router = () => {
  // Members .------------------------------------------------------------------
  const { initialized } = useKeycloak();

  // Render. -------------------------------------------------------------------
  if (!initialized) {
    return <Backdrop loading={true} />;
  } else {
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* First Connection. --------------------------------------------- */}
          <Route path="/connexion/:id" element={<Connexion />} />

          {/* Utilisateur. --------------------------------------------------- */}
          <Route
            path="/:tenant/consentement/:surveyId?"
            element={
              <PrivateRoute role={Roles.PATIENT}>
                <Consentement />
              </PrivateRoute>
            }
          />

          {/* Gestionnaire. -------------------------------------------------- */}
          <Route
            path="/:tenant/pro/:mode?/:subMode?/:id?"
            element={
              <PrivateRoute role={Roles.MEDIC}>
                <ConsentPro />
              </PrivateRoute>
            }
          />
          {/* Investigateur. -------------------------------------------------- */}
          <Route
            path="/:tenant/pro/consentement/:trialId"
            element={
              <PrivateRoute role={Roles.MEDIC}>
                <ConsentValidTrial />
              </PrivateRoute>
            }
          />

          {/* Other. --------------------------------------------------------- */}
          <Route path="/" element={<Login />} />
          <Route path="/notFound" element={<NotFound />} />
          <Route path="/:tenant" element={<Login />} />
          <Route path="/:tenant/notFound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    );
  }
};

export default Router;
