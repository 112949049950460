import { createContext } from "react";

// =============================================================================
// KeycloakContext...
// =============================================================================
export type KeycloakContextType = {
  keycloak: any;
  initialized: boolean;
  isAuthenticated: boolean;
};
export const KeycloakContext = createContext<KeycloakContextType>({
  keycloak: {},
  initialized: false,
  isAuthenticated: false,
});
