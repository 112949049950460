// =============================================================================
// Roles...
// =============================================================================
export enum Roles {
  NONE = "null",
  PATIENT = "patient",
  MEDIC = "medic",
}

// =============================================================================
// MedicRoles...
// =============================================================================
export enum MedicRoles {
  PROMOTEUR = "trial_promoter",
  INVESTIGATEUR = "investigator",
  EASYCONSENT = "easyconsent",
}

// =============================================================================
// MedicTypes...
// =============================================================================
export enum MedicTypes {
  HOSPIT = "HOSPIT",
  RGPD = "RGPD",
  TRIAL = "TRIAL",
}
