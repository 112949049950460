import * as React from "react";
import { theme } from "@docaposte-agility/da-design-system";

import { Grid, useMediaQuery } from "@mui/material";

// =============================================================================
// DecorationsPage
// =============================================================================
interface DecorationsPageProps {
  logo: string;
}
const DecorationsPage: React.FC<DecorationsPageProps> = ({ logo }) => {
  // Members. ------------------------------------------------------------------
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const mobileAndTablette = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        overflow: "hidden",
        width: desktop ? "496px" : "303px",
        backgroundColor: "cobalt.white",
        minHeight: "965px",
      }}
    >
      <Grid
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        {/* Logo clinique */}
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          padding="219px 0px 581px 0px"
        >
          <img
            src={logo}
            style={{
              border: "none",
              height: "101px",
            }}
            alt=""
          />
        </Grid>

        {/* Blue semi-circle */}
        <div
          style={{
            position: "absolute",
            top: "49.5%",
            left: desktop ? "-34%" : "-70%",
            width: "266.006px",
            height: "263.5px",
            borderRadius: "266.006px 266.006px 0 0",
            backgroundColor: "blue",
            clipPath: desktop ? "inset(0 0 63.5% 0)" : "inset(0 0 80.25% 0)",
            transform: "rotate(90deg)",
          }}
        />

        {/* Yellow circle */}
        <div
          style={{
            position: "absolute",
            top: "63.60%",
            left: desktop ? "87%" : "118%",
            width: "266.006px",
            height: "263.5px",
            borderRadius: "266.006px",
            backgroundColor: "#FFCC00CC",
            clipPath: desktop ? "inset(0 25.5% 0 0)" : "inset(0 70.5% 0 0)",
            transform: "translate(-50%, -50%)",
          }}
        />

        {/* Rounded rectangle */}
        <div
          style={{
            position: "absolute",
            width: "1183.602px",
            height: "263.5px",
            top: "50%",
            left: desktop ? "19.25%" : "17.25%",
            borderRadius: "184px",
            border: "blue solid 1px",
            clipPath: desktop ? "inset(0 66% 0 0)" : "inset(0 78.75% 0 0)",
          }}
        />

        {/* Logo Docaposte */}
        {!mobileAndTablette && (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="flex-end"
            sx={{
              height: "60px",
              position: "absolute",
              bottom: "0",
              right: "0",
              paddingBottom: "10px",
            }}
          >
            <img
              src="/images/techTrust.png"
              style={{
                border: "none",
                width: "112px",
                pointerEvents: "none",
                cursor: "pointer",
              }}
              alt=""
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DecorationsPage;
