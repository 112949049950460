export const getFR = async () => {
  const response = await fetch('/languages/fr.json');
  const translations = await response.json();
  return translations;
};

export const getEN = async () => {
  const response = await fetch('/languages/en.json');
  const translations = await response.json();
  return translations;
};
