import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { CobaltIcon, theme } from "@docaposte-agility/da-design-system";

// Services/Utils. -------------------------------------------------------------
import { parseContent } from "utils/parseContent";

// =============================================================================
// ConsentValidSignIntro...
// =============================================================================
interface ConsentValidSignIntroProps {}
const ConsentValidSignIntro: React.FC<ConsentValidSignIntroProps> = () => {
  // Members. ------------------------------------------------------------------
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { t } = useTranslation();

  // Render. -------------------------------------------------------------------
  return (
    <Paper
      sx={{
        borderRadius: "0.5rem",
        backgroundColor: "cobalt.bleuDigital05",
        border: "1px solid",
        borderColor: "cobalt.bleuDigital10",
      }}
    >
      <Grid
        container
        direction={mobile ? "column" : "row"}
        justifyContent="flex-start"
        alignItems="center"
        columns={12}
        sx={{ m: "0rem", p: "1.5rem" }}
      >
        {/* Icon. ---------------------------------------------------------- */}
        <Grid
          item
          container
          alignSelf="center"
          justifyContent="center"
          xs="auto"
          sx={{ m: "0rem", p: "0rem" }}
        >
          <CobaltIcon
            name="sign-2"
            fontSize="1.5rem"
            sx={{
              // verticalAlign: "middle",
              color: "cobalt.userBlue",
            }}
          />
        </Grid>

        {/* Texte. --------------------------------------------------------- */}
        <Grid item xs sx={{ ml: "1.5rem", p: "0rem" }}>
          <Typography
            variant="subtitle1"
            sx={{
              p: "0rem",
              m: "0rem",
              textAlign: mobile ? "center" : "start",
              color: "cobalt.ultramarine",
            }}
          >
            {parseContent(t("user.consentValid.step5.sign.intro"))}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default ConsentValidSignIntro;
