import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { CobaltIcon, theme } from "@docaposte-agility/da-design-system";

// Components. -----------------------------------------------------------------
import { contextECT } from "app/context/Context";
import ConsentBoxWhite from "app/components/common/box/ConsentBoxWhite";

// Services/Utils. -------------------------------------------------------------
import { parseContent } from "utils/parseContent";
import { useConsentPdf } from "services/survey/useConsentPdf.hook";
import { dateLog } from "utils/date/dateLog";
import { download, formatFilename } from "utils/download";

// =============================================================================
// ConsentValidEndSummary...
// =============================================================================
interface ConsentValidEndSummaryProps {}
const ConsentValidEndSummary: React.FC<ConsentValidEndSummaryProps> = () => {
  // Members. ------------------------------------------------------------------
  const { contextSurvey } = React.useContext(contextECT);
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { t } = useTranslation();

  // Handlers. -----------------------------------------------------------------
  const handleDownloadPDF = () => {
    consentPdf({
      surveyId: contextSurvey.id,
    });
  };

  // Backend. ------------------------------------------------------------------
  const { mutate: consentPdf } = useConsentPdf({
    onSuccess: (data) => {
      console.debug(dateLog("BKO", "consentPdf.onSuccess"), data);
      download(
        data.data,
        formatFilename(
          `${contextSurvey["id"]}_${contextSurvey["title"]}_consent`,
        ),
      );
    },
    onError: (error) => {
      console.debug(dateLog("BKO", "consentPdf.onError"), error);
    },
  });

  // Render. -------------------------------------------------------------------
  return (
    <ConsentBoxWhite title="">
      <Grid
        container
        direction={mobile ? "column" : "row"}
        justifyContent="flex-start"
        alignItems="center"
        columns={12}
        sx={{ m: "0rem", p: "1rem" }}
      >
        {/* Icon. ---------------------------------------------------------- */}
        <Grid
          item
          alignSelf={mobile ? "center" : "flex-start"}
          xs="auto"
          sx={{ m: "0rem 0.5rem", p: "0rem" }}
        >
          <CobaltIcon
            name="check"
            fontSize="1.5rem"
            sx={{ color: "cobalt.greenIcon" }}
          />
        </Grid>

        {/* Texte. ------------------------------------------------------- */}
        <Grid item xs>
          <Typography
            variant="subtitle1"
            sx={{
              p: "0rem",
              m: "0rem",
              textAlign: mobile ? "center" : "start",
              color: "cobalt.ultramarine",
            }}
          >
            {parseContent(
              t("user.consentValid.step6.accept.title", {
                title: contextSurvey["title"],
              }),
            )}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              p: "1rem 0rem 0rem",
              m: "0rem",
              whiteSpace: "pre-line",

              textAlign: mobile ? "center" : "start",
              color: "cobalt.ultramarine",
            }}
          >
            {parseContent(t("user.consentValid.step6.accept.message"))}
          </Typography>
        </Grid>

        {/* Action. --------------------------------------------------------- */}
        <Grid item xs={4} sx={{ m: "0rem", p: "1rem" }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth={true}
            onClick={handleDownloadPDF}
          >
            {t("user.consentValid.step6.accept.downloadPDF")}
          </Button>
        </Grid>
      </Grid>
    </ConsentBoxWhite>
  );
};
export default ConsentValidEndSummary;
