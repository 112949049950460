import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

// Services/Utils. -------------------------------------------------------------
import { parseContent } from "utils/parseContent";

// =============================================================================
// ConsentBoxWhite...
// =============================================================================
interface ConsentBoxWhiteProps {
  title: string;
  children?: React.ReactNode;
  canExpand?: boolean;
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded) => void;
}
const ConsentBoxWhite: React.FC<ConsentBoxWhiteProps> = ({
  title,
  children,
  canExpand = false,
  isExpanded = true,
  setIsExpanded,
}) => {
  // Render. -------------------------------------------------------------------
  return (
    <Paper
      sx={{
        p: "0rem",
        m: "1rem 0rem",
        borderRadius: "0.5rem",
        backgroundColor: "cobalt.white",
        boxShadow: "0px 4px 8px 0px rgba(102, 109, 146, 0.08)",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="baseline"
        sx={{ width: "100%", p: "0rem", m: "0rem" }}
      >
        <Grid item sx={{ width: "100%", p: "0rem", m: "0rem" }}>
          {canExpand ? (
            <>
              <Accordion defaultExpanded expanded={isExpanded}>
                <AccordionSummary
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pr: "24px",
                    alignItems: "center",
                  }}
                  expandIcon={
                    <CobaltIcon
                      name={"chevron-down"}
                      sx={{
                        color: "cobalt.ultramarine",
                        fontSize: 28,
                      }}
                    />
                  }
                  onClick={() => {
                    if (setIsExpanded && isExpanded !== undefined) {
                      setIsExpanded(!isExpanded);
                    }
                  }}
                >
                  {/* Title. --------------------------------------------------------- */}
                  {title !== "" && (
                    <>
                      <Typography
                        variant="h2"
                        sx={{
                          m: "0.75rem 1rem",
                          p: "0rem",
                          color: "cobalt.ultramarine",
                        }}
                      >
                        {parseContent(title)}
                      </Typography>
                      <Divider sx={{ backgroundColor: "cobalt.grey10" }} />
                    </>
                  )}
                </AccordionSummary>
                <AccordionDetails sx={{ m: "0rem", p: "0rem" }}>
                  {/* Body. ---------------------------------------------------------- */}
                  <Stack sx={{ m: "0rem", p: "0rem" }}>{children}</Stack>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <Stack>
              {title !== "" && (
                <>
                  <Typography
                    variant="h2"
                    sx={{
                      m: "1rem 1.5rem",
                      p: "0rem",
                      color: "cobalt.ultramarine",
                    }}
                  >
                    {parseContent(title)}
                  </Typography>
                  <Divider sx={{ backgroundColor: "cobalt.grey10" }} />
                </>
              )}

              <Stack sx={{ m: "0rem", p: "0rem" }}>{children}</Stack>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConsentBoxWhite;
