import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// Services/Utils. -------------------------------------------------------------
import keycloak from "config/keycloak";

// =============================================================================
// useConsentPdf...
// =============================================================================
export const useConsentPdf = (
  config: UseMutationOptions<
    { data: any },
    AxiosError,
    {
      surveyId: string;
    }
  > = {},
) => {
  return useMutation(
    ({ surveyId }) =>
      Axios.get("/surveys/" + surveyId + "/consentPdf", {
        baseURL: window.__ENV__.REACT_APP_BO_SIGN_URL,
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }),
    {
      ...config,
    },
  );
};
